import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Menu } from "antd";
//import "antd/dist/antd.css";
import { Link } from "gatsby";
import { MenuOutlined } from "@ant-design/icons";
import Dropdown from "./Dropdown.js"
import DropdownP from "./DropdownP.js"
import DropdownI from "./DropdownI.js"
import DropdownM from "./DropdownM.js"


import Logo from "../../images/Home/logo2023.png";
import Logowhite from "../../images/Home/logo2023white.png";

const NavStyled =styled.nav`
background: ${p=>p.trans? "linear-gradient(180deg, #1f1f1f 0%, rgba(217, 217, 217, 0) 100%)":"rgba(255, 255, 255,1)"};
 
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  z-index: 10;
position: fixed;
top:0px;
left:0px;
width: 100vw;
//opacity: ${p=>p.trans? "0":"1"};
transition: all 0.5s ease;



`

const Hamburgercontent = styled.div`
display:none;

@media (max-width: 900px) {
  display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 35%);
    font-size: 1.8rem;
    cursor: pointer;
}

`

const UlStyled =styled.ul`
display: grid;
grid-template-columns: repeat(6, auto);
grid-gap: 10px;
list-style: none;
text-align: center;
width: 80vw;
justify-content: end;
margin-right: 2rem;
margin-bottom: 0px;
//background:  ${p=>p.trans? "rgba(255, 255, 255, 0.0)":"rgba(255, 255, 255,1)"};


@media (max-width: 900px){
  display: ${p => p.active ? "flex":"none"};
    flex-direction: column;
    width: 80%;
    height: auto;
    position: absolute;
    top: 90px;
    //left: -100%;
    right:10%;
    margin:0px;
    opacity:  ${p => p.active ? "1":"0"};
    transition: all 0.5s ease;
    border-radius: 8px;
    background-color:var(--blanco);
}


`

const LiStyled =styled.li`
display: flex;
align-items: center;
height: 78px;

@media (max-width: 900px) {
  height: 48px; 
  svg{
  width: 20%;
}

}


`

const LinkS = styled(Link)`
//color: white;
text-decoration: none;
padding: 0.5rem 1rem;
font-size:16px;
color: ${p=>p.trans? "var(--blanco)":"var(--negro)"};


:hover{
  background-color:${p=>p.trans? "none":"#f4f4f4"} ;
  color: ${p=>p.trans? "var(--celeste)":"var(--negro)"};
  //font-weight: ${p=>p.trans? "400":"400"};
  //text-shadow:${p=>p.trans? "0.5px 0px 0px var(--celeste)":"0px 0px 0px white"};
  border-radius: 12px;
}

 @media (max-width: 900px){
  text-align: start;
    padding: 2rem;
    width: 80%;
    display: flex;
    color: var(--negro);
    padding: 0px 01rem;
    justify-content:space-between;
align-items: center;
    :hover{
  background-color:${p=>p.trans? "none":"#f4f4f4"} ;
  color:  "var(--celeste)";
}

 }

`

const Link2 = styled(Link)`
color: white;
text-decoration: none;
padding: 0.0rem 0rem;
color:var(--negro);

 @media (max-width: 900px){
  text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
 }

`


const ImgS= styled.img`
 color: #fff;
 justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  //font-size: 2rem;
  padding:12px 0px 6px 0px ;
height:96px ;
@media (max-width: 900px){
  position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    margin-left: 4px;
    height: 70px;
}
`

const Blackoverlay = styled.div`

display:none ;
@media (max-width: 900px) {
  display:flex; ;
  width: 100%;
height: 100%;
z-index:2;
position:absolute ;
border-radius:8px;
background-color:rgba(100,100,100,0.5);
}

`


const Navbar = () => {


  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownp, setDropdownp] = useState(false);
  const [dropdowni, setDropdowni] = useState(false);
  const [dropdownm, setDropdownm] = useState(false);
  const [betransparent, setBetransparent] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);



  useEffect(() => {
const hanldeScroll = () => {
const show = window.scrollY >500
if (show) {
setBetransparent(false)
}else{
  setBetransparent(true)
}

}
document.addEventListener('scroll',hanldeScroll)
return() => {
  document.removeEventListener('scroll', hanldeScroll)
}
  },[])

  const closeMobileMenuQ = () =>{
    if (window.innerWidth < 960) {
      setDropdown(true);
    } else {
      setClick(false);
    }
  }; 

  const closeMobileMenuP = () =>{
    if (window.innerWidth < 960) {
      setDropdownp(true);
    } else {
      setClick(false);
    }
  };


  const closeMobileMenuI = () =>{
    if (window.innerWidth < 960) {
      setDropdowni(true);
    } else {
      setClick(false);
    }
  };

  const closeMobileMenuM = () =>{
    if (window.innerWidth < 960) {
      setDropdownm(true);
    } else {
      setClick(false);
    }
  };



  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };


  const onMouseEnterP = () => {
    if (window.innerWidth < 960) {
      setDropdownp(false);
    } else {
      setDropdownp(true);
    }
  };

  const onMouseLeaveP = () => {
    if (window.innerWidth < 960) {
      setDropdownp(false);
    } else {
      setDropdownp(false);
    }
  };

  const onMouseEnterI = () => {
    if (window.innerWidth < 960) {
      setDropdowni(false);
    } else {
      setDropdowni(true);
    }
  };

  const onMouseLeaveI = () => {
    if (window.innerWidth < 960) {
      setDropdowni(false);
    } else {
      setDropdowni(false);
    }
  };

  const onMouseEnterM = () => {
    if (window.innerWidth < 960) {
      setDropdownm(false);
    } else {
      setDropdownm(true);
    }
  };

  const onMouseLeaveM = () => {
    if (window.innerWidth < 960) {
      setDropdownm(false);
    } else {
      setDropdownm(false);
    }
  };

  return (

    <>
      <NavStyled  trans = {betransparent}>
        <Link2 to='/'  onClick={closeMobileMenu}>
        <ImgS  src={betransparent?Logowhite:Logo} alt="logo"/>
          
        </Link2>
        <Hamburgercontent onClick={handleClick}> <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="currentColor" fillRule="evenodd" d="M3 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z" clipRule="evenodd"/></svg></Hamburgercontent>
        <UlStyled className="text" active ={click} trans = {betransparent}>
          <LiStyled>
            <LinkS to='/'  onClick={closeMobileMenu} trans={betransparent}>
              Inicio
            </LinkS>
          </LiStyled>
          <LiStyled
            
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={ closeMobileMenuQ }
          >
            <LinkS
              to='/quienesomos/'
              trans= {betransparent}
              
            >
              Quienes somos 
            </LinkS>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.6em" height="0.6em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><rect x="0" y="0" width="16" height="16" fill="none" stroke="none" /><path fill={betransparent?"var(--blanco)":"var(--negro)"} d="M2 5.56L2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z"/></svg>
            {dropdown && <Dropdown />}
          </LiStyled>
          <LiStyled
            onMouseEnter={onMouseEnterM}
            onMouseLeave={onMouseLeaveM}
            onClick={ closeMobileMenuM }
          >
            <LinkS
              to='/metodologias/'
              trans= {betransparent}
              
            >
              Cómo trabajamos
            </LinkS>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.6em" height="0.6em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><rect x="0" y="0" width="16" height="16" fill="none" stroke="none" /><path fill={betransparent?"var(--blanco)":"var(--negro)"} d="M2 5.56L2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z"/></svg>
            {dropdownm && <DropdownM />}
          </LiStyled>
          <LiStyled
            
            onMouseEnter={onMouseEnterP}
            onMouseLeave={onMouseLeaveP}
            onClick={ closeMobileMenuP }
          >
            <LinkS
              to='/proyectos/'
              trans={betransparent}
             
            >
              Proyectos 
            </LinkS>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.6em" height="0.6em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><rect x="0" y="0" width="16" height="16" fill="none" stroke="none" /><path fill={betransparent?"var(--blanco)":"var(--negro)"} d="M2 5.56L2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z"/></svg>
            {dropdownp && <DropdownP />}
          </LiStyled>
          <LiStyled
            
            onMouseEnter={onMouseEnterI}
            onMouseLeave={onMouseLeaveI}
            onClick={ closeMobileMenuI}
          >
            <LinkS
              to='/impacto/'
              trans={betransparent}
            
            >
              Impacto 
            </LinkS>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.6em" height="0.6em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><rect x="0" y="0" width="16" height="16" fill="none" stroke="none" /><path fill={betransparent?"var(--blanco)":"var(--negro)"} d="M2 5.56L2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z"/></svg>
            {dropdowni && <DropdownI />}
          </LiStyled>
          <LiStyled >
            <LinkS
              to='/contacto/'
              trans={betransparent}
              onClick={closeMobileMenu}
            >
              Contactos
            </LinkS>
          </LiStyled>
          {(dropdowni || dropdownp || dropdown) && <Blackoverlay></Blackoverlay>}
        </UlStyled>
        
      </NavStyled>
    </>

  );
};

export default Navbar;
