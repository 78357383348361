import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-image: url(${(p) => p.image});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  @media (max-width: 900px) {
    height: 230px;
    margin-bottom: 20px;
  }
`;

const Textohighlight = styled.div`
  text-align: center;
  max-width: 1440px;
  padding: 0% 5%;
  color: var(--magenta);
  font-size: 36px;
  font-weight: 700;
  margin-top: 20px;
`;

const PublicationTitle = (props) => {
  return (
    <>
      <Container>
        <Textohighlight>{props.title}</Textohighlight>
      </Container>
    </>
  );
};

export default PublicationTitle;
