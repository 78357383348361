import * as React from "react";
import styled from "styled-components";
import Idcard from "./Idcard.js"


const Container = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-content: space-around;
justify-content: center;
align-items: center;
width: 100%;
margin-top:60px ;
margin-bottom: 60px;



`

const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;
display: grid;
//grid-row-start: 1fr 1fr ;
grid-template-columns: 1fr 1fr 1fr 1fr ;
grid-template-rows:auto ;

   
row-gap: 60px;
justify-content:center;
position: relative;
z-index:1;
justify-items: center ;


@media (max-width: 900px) {
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px; 
  column-gap:16px;
  padding:0px 30px;
}
`

const Barcolor = styled.div`
width: 100%;
height: 370px;
background-color: var(--morado);
position: absolute;
z-index: 0;
top:100px;
`



const Hero = ({cards}) => {

    return(
     
        <Container  >
           <Barcolor></Barcolor>
<Subcontainer>
         {
             cards.map((item,index)=>{
return(
    <Idcard image={item.fields.foto.fields.file.url} name={item.fields.nombre} puesto={item.fields.puesto} enlace={item.fields.linkedinUrl}></Idcard>
)
             }

             )
         }
    </Subcontainer>
    </Container>
    
      
      
    )
    
    }
    
    export default Hero;