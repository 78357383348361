import * as React from "react";
import styled from "styled-components";
import Historianorichmiditem2 from "./Historianorichmiditem2";

const Container = styled.div`
  position: relative;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: flex-start !important;
  margin: 20px 0 20px 0;
  @media (max-width: 900px) {
    width: calc(100% - 60px);
    flex-direction: column;
    align-items: center !important;
  }
`;

const MidItem = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  @media (max-width: 900px) {
    width: calc(100% - 60px);
    margin-bottom: 20px;
  }
`;

const MidItemsContainer2 = ({
  img1,
  titulo1,
  text1,
  img2,
  titulo2,
  text2,
  img3,
  titulo3,
  text3,
  img4,
  titulo4,
  text4,
}) => {
  
  return (
    <>
      <Container>
        {img1 && titulo1 && text1? (
            <MidItem>
            <Historianorichmiditem2
              img={img1}
              titulo={titulo1}
              text={text1}
              fontc={"var(--azuloscuro)"}
              fontbackground={"#53CAEC"}
            ></Historianorichmiditem2>
          </MidItem>
          ):(<></>)}
          {img2 && titulo2 &&text2? (
            <MidItem>
            <Historianorichmiditem2
              img={img2}
              titulo={titulo2}
              text={text2}
              fontc={"var(--azuloscuro)"}
              fontbackground={"#EA5C90"}
            ></Historianorichmiditem2>
          </MidItem>
          ):(<></>)}
        {img3 && titulo3 && text3? (
         <MidItem>
         <Historianorichmiditem2
           img={img3}
           titulo={titulo3}
           text={text3}
           fontc={"var(--azuloscuro)"}
           fontbackground={"#9E65C4"}
         ></Historianorichmiditem2>
       </MidItem>
        ):( <></>)}
        {img4 && titulo4 && text4? (
          <MidItem>
          <Historianorichmiditem2
            img={img4}
            titulo={titulo4}
            text={text4}
            fontc={"var(--azuloscuro)"}
            fontbackground={"#8CD460"}
          ></Historianorichmiditem2>
        </MidItem>
        ):(<></>)}
      </Container>
    </>
  );
};

export default MidItemsContainer2;
