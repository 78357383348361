import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../../utils/BoldContentful.jsx";

const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center !important;
  border-radius: 50px;
  @media (max-width: 900px) {
    height: auto;
    width: 100%;
  }
  :hover {
    background-color:${p=>p.background};
    box-shadow: 0px 0px 40px rgba(${p=>p.shad}, 0.6);
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center !important;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Text = styled.div`
  width: 100%;
  text-align: center;
`;

const Titlecontainer = styled.div`
  color: ${(p) => p.co};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 16px;
  }
  ${Container}:hover &{
    color:#FFFFFF;
  }
`;

const Textcontainer = styled.div`
  color: var(--gris);
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 16px;
  min-height: 200px;
  width: 85%;
  text-align: center;
  @media (max-width: 900px) {
    margin-bottom: 5px;
    height: auto;
  }
`;

const ItemIimg = styled.img`
  width: 180px;
  height: 180px;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100px;
`;

const LogrosRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 90%;
`;

const BulletDot = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  font-size: 18px;
  margin: 0 -5px 0 0px;
  color: #000000;
  @media (max-width: 900px) {
    font-size: 24px;
    margin: 0 1rem 0 0;
  }
  ${Container}:hover &{
    color:#FFFFFF;
  }
`;

const LogrosItem = styled.div`
  height: auto;
  width: 85%;
  text-align: center;
  font-size: 16px;
  color: #000000;
  @media (max-width: 900px) {
    height: auto;
    width: 85%;
    font-size: 16px;
  }
  ${Container}:hover &{
    color:#FFFFFF;
  }
`;

const Paragraph = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return <Text>{t.value} </Text>;
      })}
      <br></br>
    </>
  );
};

const Historianorichmiditem2 = ({ titulo, text, img, fontc, fontbackground }) => {
  const uppercaseTitle = titulo.toUpperCase()
  return (
    <Container background={fontbackground}>
      <Subcontainer>
        <ItemIimg src={img}></ItemIimg>
        <Titlecontainer co={fontc}>{uppercaseTitle}</Titlecontainer>
        <Textcontainer>
          <Text>
          {text.content.map(
                  (item, index) => {
                    return (
                      <>
                        <LogrosRow>
                          <BulletDot>•</BulletDot>
                          <LogrosItem key={index}>
                            {BoldContentful(
                              item.content[0],
                              `var(--celeste)`,
                              "18px"
                            )}
                          </LogrosItem>
                        </LogrosRow>
                        <br></br>
                      </>
                    );
                  }
                )}
          </Text>
        </Textcontainer>
      </Subcontainer>
    </Container>
  );
};

export default Historianorichmiditem2;
