import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
  position: relative;
  padding: 36px 0px 56px 0px  ;

  @media (max-width: 900px) {
    margin-top: 0px;
    padding: 0px ;
  }
`;

const Subcontainer = styled.div`
  width: 80%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Textocontent = styled.div`
  color: ${(p) => p.c};
  font-weight: 400;
  font-size: 36px;
  width: 80%;
  text-align: center;
  color: #9e65c4;
  font-family: 'Open Sans', sans-serif;
  @media (max-width: 900px) {
    font-size: 18px;
    width: 95%;
  }
`;

const Textrichcrecerintro = ({ texto }) => {
  return (
    <Container>
      <Subcontainer>
        <Textocontent>
        {texto}
        </Textocontent>
      </Subcontainer>
    </Container>
  );
};
export default Textrichcrecerintro;
