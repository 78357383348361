import * as React from "react";
import styled from "styled-components";
import Title from "../../../components/Title.js"

const Container = styled.div`
position: relative;
background-image: url(${p=>p.image});
background-size: cover;
display: flex;
flex-direction: column;
align-content: space-around;
justify-content: center;
align-items: center;
width: 100%;
height:400px;
margin-bottom: 40px;

@media (max-width: 900px) {
    height:230px;   
    margin-bottom: 40px;
}

`
const Pstyled = styled.p`
  color: var(--blanco);
  width:60% ;
  padding:20px 30px;
  text-align:center ;
  margin-bottom:36px;
z-index: 2;
  @media (max-width: 900px) {
    width:100% ;
  }
`;

const Overlay = styled.div`
position:absolute;
width: 100%;
height:100%;
background-color: rgba(${p=>p.bckcolor}, 0.95);


`

const Titlecontent = styled.div`
z-index:2;
`

const Herotitle = (props) => {
    return( 
    <Container image={props.imagen} >  
    <Overlay bckcolor={props.bgcolor}></Overlay> 
    <Titlecontent>
    <Title  color ={"var(--morado"} colortext={"var(--blanco)"} container={props.titulo} ></Title>
    </Titlecontent>
    
    <Pstyled >{props.texto}</Pstyled>
       
    </Container>
    )
    }
  
    export default Herotitle;