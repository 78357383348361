import * as React from "react";
import styled from "styled-components";



const Container = styled.div`
width:100%;
height:726px;
background-color: var(--blanco);
display: flex;
//justify-content: center;
align-items: center;
padding-top: 40px;
flex-direction: column;
position: relative;

@media (max-width: 900px) {
    height: auto;
    padding-top: 0px;
    padding-bottom: 16px;
}
`

const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;
display: flex;
justify-content:center;
z-index:1;

@media (max-width: 900px) {
  width: 100%;
}
`

const Containercard = styled.div`
position:relative;
width: 20%;
display: flex;
height:400px;
flex-direction: column;
align-items: center;
border-radius:4px ;
background-color:var(--blanco);

:hover {

    background-color:${p=>p.bckg};
    box-shadow: 0px 0px 40px rgba(${p=>p.shad}, 0.6);
    
}


@media (max-width: 900px) {
    width: 100%;
    padding-bottom:16px ;
}


`

const Cards = styled.div`
width: 100%;
padding: 0px 0px;
display: flex;
justify-content: space-between;


@media (max-width: 900px) {
    width:100%;
    padding:0px 30px;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

`
const Icono = styled.div`

height:30%;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom:10px;
justify-content: center;
img{
    width:60% ;
    z-index:1;
}
`

const Titulo = styled.div`
height:10%;
color: ${p=>p.clr};
font-weight:800;

${Containercard}:hover &{
    color:var(--blanco);
}

`

const Content = styled.div`
height:50%;
color: var(--gris) ;
text-align:center ;
padding:0 10% ;

${Containercard}:hover &{
    color:var(--blanco);
    
}
`

const Circulo = styled.div`
display:none;
height:28%;
aspect-ratio:1 ;
z-index:0;
border-radius: 50%;
position: absolute;
background-color:var(--blanco);


${Containercard}:hover &{
    display:flex;
    
}
`




const Card = (props) => {
return(
<Containercard bckg = {props.color} shad={props.shadow}>
    <Icono>
 <Circulo></Circulo>       
<img src={props.iconurl} alt="icon valores"/>
    </Icono >
    <Titulo clr={props.color}>
{props.title}
    </Titulo>
    <Content>
{props.text}
    </Content>
</Containercard>
);

}


const Valorcards = ({ card}) => {
    //console.log("cards content");
   // console.log(card.fields.cantidad);

return(
    <Container>
    <Subcontainer>
<Cards>


<Card 
color="var(--morado)" 
shadow="var(--moradorgb)" 
iconurl ={card[0].fields.icono.fields.file.url}
text={card[0].fields.descripcion}
title={card[0].fields.titulo}>
</Card>

<Card 
color="var(--magenta)" 
shadow="var(--magentargb)" 
iconurl ={card[1].fields.icono.fields.file.url}
text={card[1].fields.descripcion}
title={card[1].fields.titulo}>
</Card>

<Card 
color="var(--naranja)" 
shadow="var(--naranjargb)" 
iconurl ={card[2].fields.icono.fields.file.url}
text={card[2].fields.descripcion}
title={card[2].fields.titulo}>
</Card>

<Card 
color="#f1de02" 
shadow="var(--amarillorgb)" 
iconurl ={card[3].fields.icono.fields.file.url}
text={card[3].fields.descripcion}
title={card[3].fields.titulo}>
</Card>

<Card 
color="var(--celeste)" 
shadow="var(--celestergb)" 
iconurl ={card[4].fields.icono.fields.file.url}
text={card[4].fields.descripcion}
title={card[4].fields.titulo}>
</Card>


<Card 
color="var(--verde)" 
shadow="var(--verdergb)" 
iconurl ={card[5].fields.icono.fields.file.url}
text={card[5].fields.descripcion}
title={card[5].fields.titulo}>
</Card>

</Cards>
</Subcontainer>


    </Container>
)

}

export default Valorcards;