import React, { useState } from "react";
import { createClient } from "contentful";
const REACT_APP_CMS_SPACE_ID = "hg6uz7ijvypg";
const REACT_APP_CMS_ACCESS_TOKEN =
  "HLwP91LWorsghfv5cGrY9Sawvkee10MDuKnOH-E-FFg";

const defaultProps = {
  include: 10,
  query: {},
  parser: (data, props) => data,
};

const useContentful = (props) => {
  const client = createClient({
    space: REACT_APP_CMS_SPACE_ID,
    accessToken: REACT_APP_CMS_ACCESS_TOKEN,
  });

  const contentful = { client: client };

  const internalProps = { ...defaultProps, ...props, contentful };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [fetched, setFetched] = useState(!!data);
  const { parser } = internalProps;

  const requestContentfulData = () => {
    setLoading(true);

    fetchData(internalProps)
      .then((response) => {
        setData(parser ? parser(response, internalProps) : response);
        setLoading(false);
        setFetched(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  React.useEffect(() => {
    if (fetched) {
      setFetched(false);
    }

    requestContentfulData();
  }, []);

  return { data, error, fetched, loading };
};

export const fetchData = async (props = {}) => {
  return new Promise((resolve, reject) => {
    const { contentful, contentType, id, include, query } = props;
    const { client } = contentful;
    try {
      const request = id
        ? client.getEntry(id, { include, ...query })
        : client.getEntries({ content_type: contentType, include, ...query });

      resolve(request);
    } catch (error) {
      console.log(error);

      reject(new Error("Error getting el request"));
    }
  });
};

export default useContentful;
