import * as React from "react";
import styled from "styled-components";
import Titlerich from "../evidencia/Titlerich"

const Container = styled.div`
position: relative;
background-image: linear-gradient(
    rgba(225, 222, 220, 0.1),
    rgba(224, 222, 220, 0.1)
    ), 
url(${p=>p.image});
background-size: cover;
display: flex;
flex-direction: column;
align-content: space-around;
justify-content: space-evenly;
align-items: center;
width: 100%;
height:600px;
margin-bottom: 40px;

@media (max-width: 900px) {
    height:500px;   
    margin-bottom: 20px;
}
`
const Pstyled = styled.p`
  color: var(--blanco);
  font-family: 'Open Sans', sans-serif;
  width:45% ;
  text-align:center ;
  margin-bottom:36px;
  font-size: 32px;
  z-index: 2;
  @media (max-width: 900px) {
    width:100% ;
    margin-bottom:0px;
    font-size: 18px;
  }
`;

const Titlecontent = styled.div`
z-index:2;
margin: 100px 0 50px 0;
`

const Herotitle = (props) => {
    return( 
    <Container image={props.imagen} >  
    <Titlecontent>
    <Titlerich  color ={"var(--celeste"} colortext={"var(--blanco)"} container={props.titulo} colorbold={"var(--blanco)"}></Titlerich>
    </Titlecontent>
    <Pstyled >{props.texto}</Pstyled>
    </Container>
    )
    }
  
    export default Herotitle;