import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful3 from "../../../../utils/useContentful3";
import MetaTags from "react-meta-tags";
import Navegationevidencia from "../../../../components/comotrabajamos/evidencia/navegationevidencia.js";
import "../../../../styles/global.css";
import { Helmet } from "react-helmet";
import { CustomBreadcrumb } from "../../../../components/comotrabajamos/evidencia/CustomBreadcrumb.js";
import Herotitle from "../../../../components/comotrabajamos/evidencia/Herotitle.js"
import MidItemsContainer from "../../../../components/comotrabajamos/evidencia/MidItems.js";
import TextMidContainer from "../../../../components/comotrabajamos/evidencia/Textrichcrecerintro.js";
import PieChart from "../../../../components/comotrabajamos/evidencia/Piechart.js";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Titlecontainer = styled.div`
  background-color: var(--celeste);
  padding: 0 5px;
  text-align: center;
  
  span {
    display: inline-block;
    color: ${(p) => p.co};
    font-size: 38px;
    font-weight: 700;
    
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }
`;


const links = [
  { name: "Cómo trabajamos", to: "/metodologias" },
  { name: "Área de Data y Evidencia", to: "/metodologias/evidencia" }
];

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const DataEvEntryId = "5kXlxEnljR82TtlDhkyiZh";

const IndexPage = ({ location }) => {

  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  const { data: dataevidence, fetched: fetched } = useContentful3({
    id: DataEvEntryId,
  });

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{"Nuestro Compromiso"}</title>
            <meta
              name={"Nuestro Compromiso"}
              content={"Nuestro Compromiso"}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/metodologias/evidencia/compromiso/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/metodologias/evidencia/compromiso/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/metodologias/evidencia/compromiso/"
            />
          </MetaTags>

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/metodologias/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social País`,
                    item: `https://www.aporta.org.pe/metodologias/evidencia/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Data y Evidencia`,
                    item: `https://www.aporta.org.pe/metodologias/evidencia/compromiso/`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <CustomBreadcrumb links={links} currentPage="Nuestro Compromiso" />
              <br></br>
              {dataevidence.fields.backgroundImage.fields.file.url && dataevidence.fields.mainTitle && dataevidence.fields.subtext1 && dataevidence.fields.subtext2 ? (
              <Herotitle
                imagen={dataevidence.fields.backgroundImage.fields.file.url}
                titulo={dataevidence.fields.mainTitle}
                texto={dataevidence.fields.subtext1}
              ></Herotitle>):(<></>)}
              <Pstyled>{""}</Pstyled>
              <TextMidContainer
                texto={dataevidence.fields.subtext2}
              ></TextMidContainer>
              <br></br>
              <br></br>
              <Navegationevidencia selection={"1"}></Navegationevidencia>
              <br></br>
              <br></br>
              <>
                  {dataevidence.fields.seccionTitle1 ? (
                  <Titlecontainer co={"#FFFFFF"}>
                  <span>{dataevidence.fields.seccionTitle1}</span>
                  </Titlecontainer>
                  ):(<></>)}
                  <MidItemsContainer
                    img1={dataevidence?.fields?.seccionCards1?.[0]?.fields?.cardImage?.fields?.file?.url || null}
                    titulo1={dataevidence?.fields?.seccionCards1?.[0]?.fields?.cardTitle || null}
                    text1={dataevidence?.fields?.seccionCards1?.[0]?.fields?.cardText || null}

                    img2={dataevidence?.fields?.seccionCards1?.[1]?.fields?.cardImage?.fields?.file?.url || null}
                    titulo2={dataevidence?.fields?.seccionCards1?.[1]?.fields?.cardTitle || null}
                    text2={dataevidence?.fields?.seccionCards1?.[1]?.fields?.cardText || null}

                    img3={dataevidence?.fields?.seccionCards1?.[2]?.fields?.cardImage?.fields?.file?.url || null}
                    titulo3={dataevidence?.fields?.seccionCards1?.[2]?.fields?.cardTitle || null}
                    text3={dataevidence?.fields?.seccionCards1?.[2]?.fields?.cardText || null}

                    img4={dataevidence?.fields?.seccionCards1?.[3]?.fields?.cardImage?.fields?.file?.url || null}
                    titulo4={dataevidence?.fields?.seccionCards1?.[3]?.fields?.cardTitle || null}
                    text4={dataevidence?.fields?.seccionCards1?.[3]?.fields?.cardText || null}
                  />
                </>
                <br></br>
                <br></br>
                <>
                  {dataevidence.fields.seccionTitle2 ? (
                  <Titlecontainer co={"#FFFFFF"}>
                  <span>{dataevidence.fields.seccionTitle2}</span>
                  </Titlecontainer>
                  ):(<></>)}
                </>
              <br></br>
              <PieChart datos={dataevidence.fields.cakeDiagram}></PieChart>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
