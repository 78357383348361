import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../../components/Layout/Layout.js";
import useContentful from "../../../../../utils/useContentful.jsx";
import useContentful2 from "../../../../../utils/useContentful2.jsx";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Title from "../../../../../components/Title.js";
import Navegationbeca from "../../../../../components/proyectos/pais/becatalento/navegationbeca.js";
import Bottomsectionbases from "../../../../../components/proyectos/pais/becatalento/Bottomsectionbases.js";
import Historianorich from "../../../../../components/proyectos/pais/becatalento/Historianorich.js";
import Historianorich2 from "../../../../../components/proyectos/pais/Historianorich.js";
import Historianorichright from "../../../../../components/proyectos/pais/becatalento/Historianorichright.js";
import RichTextWithNestedLists from "../../../../../utils/RichTextWithNestedLists.jsx";
import Textrichcrecer from "../../../../../components/proyectos/pais/crecer/Textrichcrecer.js";
import "../../../../../styles/global.css";
import { Helmet } from "react-helmet";
import { CustomBreadcrumb } from "../../../../../components/CustomBreadcrumb.js";
import Alcance from "../../../../../images/Home/alcance_cyan.svg";
import Lineactive from "../../../../../images/Home/vector_active.svg";
import Linedeactive from "../../../../../images/Home/vector_deactive.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ImgAlcance = styled.img`
  width: 150px;
  height: auto;
`;

const TextoAct = styled.div`
  width: 100%;
  max-width: 1440px;
  color: var(--gris);
  padding: 0px 5%;
  padding-top: 2%;
  padding-bottom: 4%;
  margin-top: 0%;
  margin-bottom: 0%;
  text-align: left;
  font-size: 22px;

  @media (max-width: 500px) {
    font-size: 15px;
    padding: 2% 10%;  }

`;

const TituloAct = styled.div`
  width: 100%;
  max-width: 1440px;
  font-size: 38px;
  color: var(--verde);
  font-weight: 700;
  padding: 0px 5%;
  margin-top: 6%;
  margin-bottom: 0%;
  text-align: left;
  @media (max-width: 900px) {
    padding: 0px 0px;
    font-size: 34px;
  }
  @media (max-width: 500px) {
    font-size: 34px;
    padding: 0 10%;

  }
`;

const Rectangulo = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--gris);
  width: 600px;
  height: 96px;
  background-color: #f5f5f5;
  padding: 0px;

  @media (max-width: 900px) {
    width: 90%;
    height: auto;
    flex-direction: column;
    padding: 16px;
    p {
      font-size: 18px;
    }
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  img {
    width: 80%;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const Titulo = styled.div`
  width: 90%;
  max-width: 1440px;
  font-size: 38px;
  color: ${(p) => (p.col ? p.col : "var(--celeste)")};
  font-weight: 700;
  padding: 0px 4%;
  @media (max-width: 900px) {
    padding: 0px 0px;
    font-size: 34px;
  }
`;

const Universidadessection = styled.div`
  width: 66%;
  max-width: 1440px;
  background-color: #f6fcfe;
  padding: 36px;

  i {
    font-style: italic;
    color: var(--negro);
    padding: 0px 24px;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  p {
    color: var(--celesteoscuro);
    margin-bottom: 12px;
    font-weight: 700;
  }

  ul {
    padding: 0px 36px;
    color: var(--negro);
    list-style-type: circle;
    li {
      line-height: 1.4;
      margin-bottom: 8px;
    }
  }
`;
const Requisitossection = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    table {
      width: 100%;
    }
  }

  p {
    color: var(--gris);
  }

  table {
    width: 70%;
    margin-bottom: 36px;
    margin: 25px auto;
    border-collapse: collapse;
    border: 2px solid #eee;
    border-bottom: 0px solid #00cccc;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), tr {
      &:hover {
        background: #f4f4f4;

        td {
          color: var(--gris);
        }
      }
    }
    th,
    tr,
    td {
      width: 33%;
      color: var(--gris);
      border: 2px solid #eee;
      padding: 12px 35px;
      border-collapse: collapse;
      strong {
        color: var(--magenta);
        font-weight: bold;
      }
    }
  }
`;

const Text = styled.div`
  width: 100%;

  :nth-child(1) {
    color: var(--celesteoscuro);
    margin-bottom: 12px;
    font-weight: 700;
  }
`;

const Text2 = styled.div`
  width: 100%;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
  justify-content: center;

  img {
    height: 120px;
  }
  a:hover {
    transform: scaleX(1.03);
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    img {
      height: 100px;
    }
  }
`;

const LineadetiempoColumn = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const LineadetiempoRow = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
`;

const TimelineItemTextDiv = styled.div`
  text-align: center;

  font-weight: bolder;
  font-size: 24px;
  margin-top: 7px;
  margin-bottom: 7px;
  color: #41afe4;
`;

const TimelineItemTextDivDeactive = styled.div`
  text-align: center;

  font-weight: bolder;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 24px;
  color: #d9d9d9;
`;

const Textcontainer = styled.h1`
  font-weight: 600;
  font-size: 34px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 900px) {
    width: 60%;
    text-align: center;
  }
`;

const Texttitletimeline = styled.h1`
  font-weight: bolder;
  font-size: 34px;
  margin-bottom: 24px;
 
  color: #38467e;
  text-align: center;
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
  }
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";
const HomeEntryId = "2oDMDgz8LGunEWkUp6HTSA";
const BecaTalentoEntry = "2skIqYayPxU9NJtmaEwuys";

const Paragraph = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return t.marks[0] ? <i>{t.value}</i> : <Text>{t.value} </Text>;
      })}
      <br />
    </>
  );
};

const Paragraph21 = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return <Text2>{t.value} </Text2>;
      })}
    </>
  );
};

const Paragraph22 = ({ content }) => {
  return (
    <>
      {content.map((t2, index) => {
        {
          return <Paragraph21 content={t2.content}></Paragraph21>;
        }
      })}
    </>
  );
};

const links = [
  { name: "Proyectos", to: "/proyectos" },
  { name: "Innovación Social País", to: "/proyectos/pais" },
  { name: "Beca Talento", to: "/proyectos/pais/becatalento" },
];

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });
  const { data: home, fetched: fetched3 } = useContentful({ id: HomeEntryId });
  const { data: becatalento, fetched: fetched4 } = useContentful2({
    id: BecaTalentoEntry,
  });

 
 

  // titulo={adopcion.fields.heroTitulo} prop example
  return (
    <>
      {fetched && fetched2 && fetched3 && fetched4 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitlePais[2]}</title>
            <meta
              name={proyectos.fields.metaDescriptionPais[2]}
              content={proyectos.fields.metaContentPais[2]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/becatalento/puno"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/becatalento/puno"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/becatalento/puno"
            />
          </MetaTags>

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social País`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Beca talento`,
                    item: `https://www.aporta.org.pe/proyectos/pais/becatalento`,
                  },
                  {
                    "@type": "ListItem",
                    position: 5,
                    name: `Beca talento Puno`,
                    item: `https://www.aporta.org.pe/proyectos/pais/becatalento/puno`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <CustomBreadcrumb links={links} currentPage="Beca Talento Puno" />
              <br></br>
              <Title
                color={"var(--celeste"}
                colortext={"var(--negro)"}
                container={becatalento.fields.abouttitle}
              ></Title>
              <Pstyled>{becatalento.fields.aboutsubtitle}</Pstyled>
              <br></br>
              <Navegationbeca selection={"2"}></Navegationbeca>
              <br></br>
              <br></br>
              <br></br>
              {becatalento.fields.punotimelines ? (
                <>
                  <div>
                 
                    <Timeline>
                      <LineadetiempoColumn>
                        {becatalento.fields.punotimelinesall.fields.hitosLneaDeTiempo.hitos.map(
                          (timeline, indextime) => (
<>
{indextime == 0 && <Texttitletimeline>
                      {
                        becatalento.fields.punotimelinesall.fields
                          .tituloLineaDeTiempo
                      }
                    </Texttitletimeline>
                    }
                    {indextime == 1 && 
                    <>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Texttitletimeline>
                      {
                        becatalento.fields.punotimelinesall.fields
                          .tituloLineaDeTiempoOpcional
                      }
                    </Texttitletimeline>
                    </>}
                    {indextime == 2 && 
                    <>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Texttitletimeline>
                      {
                        becatalento.fields.punotimelinesall.fields
                          .tituloLineaDeTiempoAgregado
                      }
                    </Texttitletimeline>
                    </>}
                           { timeline.map((hitos, index2) => (
                              <>
                              <>
                                <div>
                                  {index2 == 0 ? (
                                    <>
                                      {hitos.hito_activo == "Si" ? (
                                        <LineadetiempoColumn>
                                          <LineadetiempoRow>
                                            <a
                                              style={{ textDecoration: "none" }}
                                              target="_blank"
                                              href={hitos.hito_URL}
                                            >
                                              <TimelineItemTextDiv>
                                                {hitos.hito_titulo}
                                              </TimelineItemTextDiv>
                                            </a>
                                          </LineadetiempoRow>
                                        </LineadetiempoColumn>
                                      ) : (
                                        <LineadetiempoColumn>
                                          <LineadetiempoRow>
                                           
                                              <TimelineItemTextDivDeactive>
                                                {hitos.hito_titulo}
                                              </TimelineItemTextDivDeactive>
                                            
                                          </LineadetiempoRow>
                                        </LineadetiempoColumn>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {hitos.hito_activo == "Si" ? (
                                        <LineadetiempoColumn>
                                          <img src={Lineactive}></img>
                                          <LineadetiempoRow>
                                            <a
                                              style={{ textDecoration: "none" }}
                                              target="_blank"
                                              href={hitos.hito_URL}
                                            >
                                              <TimelineItemTextDiv>
                                                {hitos.hito_titulo}
                                              </TimelineItemTextDiv>
                                            </a>
                                          </LineadetiempoRow>
                                        </LineadetiempoColumn>
                                      ) : (
                                        <LineadetiempoColumn>
                                          <img src={Linedeactive}></img>
                                          <LineadetiempoRow>
                                           
                                              <TimelineItemTextDivDeactive>
                                                {hitos.hito_titulo}
                                              </TimelineItemTextDivDeactive>
                                        
                                          </LineadetiempoRow>
                                        </LineadetiempoColumn>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                           
                              
                            </>
                            )  )
                          }
                           </>
                          )
                        )}
                      </LineadetiempoColumn>
                    </Timeline>
                  </div>
                </>
              ) : (
                <></>
              )}
              <br></br>
              <br></br>
              <Historianorichright
                titulo={becatalento.fields.punofirstRowTitle}
                text={becatalento.fields.punofirstRowText.content}
                fontc={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={becatalento.fields.punofirstRowImagen.fields.file.url}
                    alt="becada"
                  />
                </Cardcontainer>
              </Historianorichright>

              <br></br>
              <Titulo>{becatalento.fields.punosecondRowTitle}</Titulo>
              <br></br>

              <Universidadessection>
                {becatalento.fields.punosecondRowText.content.map(
                  (field, index) => {
                    return <Paragraph content={field.content}></Paragraph>;
                  }
                )}

                <i>{becatalento.fields.punosecondRowBottom} </i>
              </Universidadessection>
              <br></br>
              <br></br>

              <Titulo col="var(--magenta)">
                {becatalento.fields.punoTableTitle}
              </Titulo>
              <Requisitossection>
                <br></br>
                <p>{becatalento.fields.punoTableText}</p>

                <table>
                  <tr>
                    <td>
                      <strong>
                        {becatalento.fields.punoTableColumn1Title}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {becatalento.fields.punoTableColumn2Title}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {becatalento.fields.punoTableColumn3Title}
                      </strong>
                    </td>
                  </tr>
                  {becatalento.fields.punoTableItemsColumn1.map(
                    (field, index) => {
                      return (
                        <>
                          {becatalento.fields.punoTableItemsColumn2.map(
                            (field2, index2) => {
                              return (
                                <>
                                {becatalento.fields.PunoTablaItemsColumna3.map(
                                  (field3, index3) => {
                                    return (
                                      <tr>
                                  {index == index2 & index == index3? (
                                  <> 
                                      <td>
                                        <Paragraph22
                                          content={field.fields.text.content}
                                        ></Paragraph22>
                                      </td>
                                      {field2.fields.text ? (
                                        <td>
                                          <Paragraph22
                                            content={field2.fields.text.content}
                                          ></Paragraph22>
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      
                                    
                                      {field3.fields.text ? (
                                        
                                        <td>
                                          <Paragraph22
                                            content={field3.fields.text.content}
                                          ></Paragraph22>
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                    
                                  
                                    <></>
                                  
                                  </>  
                                  ) : (
                                    <></>
                                  )}
                                </tr>
                                    )})}
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    }
                  )}
                </table>
              </Requisitossection>
              <br></br>
              <Historianorich2
                texto={becatalento.fields.punothirdRowText}
                fontc={"var(--morado)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={becatalento.fields.punothirdRowImagen.fields.file.url}
                    alt="becatalento_logros"
                  />
                </Cardcontainer>
              </Historianorich2>
              <br></br>
              <br></br>
              <br></br>
              <ImgAlcance src={Alcance} alt="becatalento_alcance" />

              <Textrichcrecer
                texto={becatalento.fields.punoAlcance}
                clr={"var(--celeste)"}
              ></Textrichcrecer>

              <TituloAct>{becatalento.fields.punoLogrosTitle}</TituloAct>
              <TextoAct>
                <RichTextWithNestedLists
                  richText={becatalento.fields.punoLogrosText}
                  colorBold={"var(--verde)"}
                ></RichTextWithNestedLists>
              </TextoAct>
              <br></br>
              <Bottomsectionbases
                style={{ margin: "0px 0px 0px 0px" }}
                clr={"var(--celeste)"}
                titulo={becatalento.fields.punoBottom.bottomTitle}
                bottomtext={becatalento.fields.punoBottom.bottomtext}
              ></Bottomsectionbases>
              <Rectangulo>
                <p>Encuéntranos en: </p>
                <div style={{ marginTop: "12px" }}>
                  <a
                    href="https://www.facebook.com/profile.php?id=100085360189978"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="48"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" fill="#53CAEC" />
                      <path
                        d="M17.1575 13.1069V10.7925C17.1575 10.1537 17.676 9.63525 18.3147 9.63525H19.472V6.74219H17.1575C15.24 6.74219 13.6858 8.29634 13.6858 10.2139V13.1069H11.3714V16H13.6858V25.2578H17.1575V16H19.472L20.6292 13.1069H17.1575Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/becatalentoperu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="48"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_241_3562)">
                        <circle cx="16" cy="16.3213" r="16" fill="#53CAEC" />
                        <path
                          d="M16.0187 12.0284C13.68 12.0284 11.8163 13.9286 11.8163 16.2308C11.8163 18.5695 13.68 20.4332 16.0187 20.4332C18.3209 20.4332 20.2211 18.5695 20.2211 16.2308C20.2211 13.9286 18.3209 12.0284 16.0187 12.0284ZM16.0187 18.9714C14.5205 18.9714 13.278 17.7655 13.278 16.2308C13.278 14.7325 14.4839 13.5266 16.0187 13.5266C17.517 13.5266 18.7229 14.7325 18.7229 16.2308C18.7229 17.7655 17.517 18.9714 16.0187 18.9714ZM21.3539 11.8822C21.3539 11.334 20.9154 10.8955 20.3673 10.8955C19.8191 10.8955 19.3806 11.334 19.3806 11.8822C19.3806 12.4303 19.8191 12.8688 20.3673 12.8688C20.9154 12.8688 21.3539 12.4303 21.3539 11.8822ZM24.1312 12.8688C24.0581 11.5533 23.7657 10.3839 22.8156 9.43382C21.8655 8.48372 20.6962 8.19138 19.3806 8.11829C18.0286 8.04521 13.9723 8.04521 12.6202 8.11829C11.3047 8.19138 10.1719 8.48372 9.18524 9.43382C8.23513 10.3839 7.94279 11.5533 7.86971 12.8688C7.79662 14.2209 7.79662 18.2771 7.86971 19.6292C7.94279 20.9447 8.23513 22.0776 9.18524 23.0642C10.1719 24.0143 11.3047 24.3067 12.6202 24.3798C13.9723 24.4528 18.0286 24.4528 19.3806 24.3798C20.6962 24.3067 21.8655 24.0143 22.8156 23.0642C23.7657 22.0776 24.0581 20.9447 24.1312 19.6292C24.2043 18.2771 24.2043 14.2209 24.1312 12.8688ZM22.3771 21.0544C22.1213 21.7852 21.5366 22.3334 20.8423 22.6257C19.7461 23.0642 17.1881 22.9546 16.0187 22.9546C14.8128 22.9546 12.2548 23.0642 11.1951 22.6257C10.4642 22.3334 9.91609 21.7852 9.62375 21.0544C9.18524 19.9946 9.29487 17.4367 9.29487 16.2308C9.29487 15.0614 9.18524 12.5034 9.62375 11.4071C9.91609 10.7128 10.4642 10.1647 11.1951 9.87234C12.2548 9.43382 14.8128 9.54345 16.0187 9.54345C17.1881 9.54345 19.7461 9.43382 20.8423 9.87234C21.5366 10.1281 22.0848 10.7128 22.3771 11.4071C22.8156 12.5034 22.706 15.0614 22.706 16.2308C22.706 17.4367 22.8156 19.9946 22.3771 21.0544Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_241_3562">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </Rectangulo>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
