import React from "react";
import "./TimeLine.css";
import styled from "styled-components";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { PiArrowsInSimpleBold } from "react-icons/pi";

const ImageContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Image = styled.img`
    width: 250px;
    height: 150px;
    @media (max-width: 900px) {
      width: 150px;
      height: 100px;
  }
`;

const Title = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 24px;
    @media (max-width: 900px) {
      font-size: 18px;
  }
`;

const Text = styled.div`
    font-family: 'Open Sans', sans-serif;
    color:var(--gris);
    font-size: 16px;
    @media (max-width: 900px) {
      font-size: 14px;
      text-align: justify;
  }
`;

const TimeLine = (elements) => {
  return (
    <React.Fragment>
      <VerticalTimeline lineColor={"#000000"}>
        {elements.elements.map((element) => {
          return (
            <VerticalTimelineElement
              contentStyle={{ borderTop: "5px solid #9E65C4" }}
              iconStyle={{ background: "#9E65C4"}} 
              icon={<PiArrowsInSimpleBold />}
            >
            <ImageContainer>
                <Title>
                    {element.fields.title}
                </Title>
                <Image src={element.fields.image.fields.file.url}/>
            </ImageContainer>
              <Text id="description">{element.fields.description}</Text>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </React.Fragment>
  );
}

export default TimeLine;
