import React, { useState ,useEffect} from "react";
import styled from "styled-components";
import { Link } from "gatsby";


const Container = styled.div`
position: relative;
width: 100%;
margin: 50px 0px;
display:flex; 
justify-content: center;
flex-direction: column ;
align-items: center;

@media (max-width: 900px) {
    margin: 30px 0px;
}
`


const Dropdown = styled.div`
position: relative;
display: flex;
flex-direction: row;
align-content: space-around;
justify-content: center;
align-items: center;
z-index:2;
background-color: var(--blanco) ;
top:0px;


@media (max-width: 900px) {
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
border-radius: 8px;
top:48px;
display: ${p=>p.act? "flex":"none"};

}
`




const Item = styled(Link)`
padding: 0px 20px;
text-decoration: ${p=>p.active? "underline":"none"} ;
color: ${p=>p.active? "var(--magenta)":"var(--negro)"};
font-weight: ${p=>p.active? "600":"400"};
text-underline-offset: 12px;
border-right: 1px solid var(--gris);
cursor: pointer;


:nth-child(1){
    
    color: ${p=>p.active? "var(--magenta)":"var(--negro)"};
}

:nth-child(2){
    
    color: ${p=>p.active? "var(--verde)":"var(--negro)"};
}

:nth-child(3){
    
    color: ${p=>p.active? "var(--morado)":"var(--negro)"};
}

:nth-child(4){
    border-right: 0px solid var(--gris); 
    color: ${p=>p.active? "var(--naranja)":"var(--negro)"};
}

@media (max-width: 900px) {
    border-right: 0px solid var(--gris);
    height:48px ;
    line-height:48px;
    background-color: var(--blanco) ;
    text-decoration:none;
    width:100%;
    :hover{
        background-color: var(--grisclaro); ;  
    }
}

`

const Buttondiv = styled.div`
display:none;

svg{

    position: absolute;
    right: 40px;
}

@media (max-width: 900px) {
    display:flex ;
    width:100%;
    border-bottom: 2px solid var(--grisclaro);
    justify-content: center;
    padding-bottom:4px ;
    flex-direction: row;
   
}

`

const Buttondown = () => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    let pagina = url.split('/',5)
    let seccion = pagina.slice(-1);



if (seccion == "") {
    return(
        <Buttondiv>
            Historia
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>
            { /* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>*/}
        </Buttondiv>
    )
}

if (seccion == "historia") {
    return(
        <Buttondiv>
            Historia
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>
          { /* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>*/}
        </Buttondiv>
    )
}

if (seccion == "proposito") {
    return(
        <Buttondiv>
            Propósito y valores
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>
        </Buttondiv>
    )
}

if (seccion == "equipo") {
    return(
        <Buttondiv>
            Equipo
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>
        </Buttondiv>
    )
}

if (seccion == "empresas") {
    return(
        <Buttondiv>
            Empresas Asociadas
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>
        </Buttondiv>
    )
}

if (seccion == "quienesomos") {
    return(
        <Buttondiv>
            Historia
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><rect x="0" y="0" width="20" height="20" fill="none" stroke="none" /><path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"/></svg>
        </Buttondiv>
    )
}
}

const Navegation = (props) => {
const [visible , Setvisible] = useState(false);

     const handleVisible = () =>{
        Setvisible(!visible);
    }

    return(
    
        <Container  >
            <div style={{width: '80%'}} onClick ={()=>handleVisible()}> <Buttondown ></Buttondown> </div>
        
         <Dropdown act = {visible}>
         <Item to='/quienesomos/historia' active={props.selection === "1"} >Historia</Item>
        <Item to='/quienesomos/proposito' active = {props.selection === "2"} >Proposito y Valores</Item>
        <Item to='/quienesomos/equipo' active = {props.selection === "3"} >Equipo</Item>
        <Item to='/quienesomos/empresas' active = {props.selection === "4"} >Empresas asociadas</Item>
        </Dropdown>   
        
    
    </Container>

    )
    
    }
    
    export default Navegation;