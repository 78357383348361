import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: ${(p) => p.color};
  border-radius: 4px;
  display: inline-block;
  font-weight: 300;
  font-size: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin: 0px 8px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Textcontainer = styled.h2`
  font-size: 48px;
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 32px;
    width: 65%;
    text-align: center;
  }
`;

const Titlerich = (props) => {
  return (
    <Container color={props.colortext}>
      <Textcontainer>
      {props.container}
        {/*{BoldContentful(props.container, props.colorbold)}*/}
      </Textcontainer>
    </Container>
  );
};

export default Titlerich;
