import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";


const Container = styled.div`
background-color:var(--celeste);
color:var(--blanco);
border-radius:4px;
display: flex;
width:48px;
height:48px;
justify-content:center;
align-items:center;

:hover{
    background-color:#47aecb; 
}

`

const Buttonarrow = (props) => {

    return(
      
        <Container >
           
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="18px" height="18px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 18 18">
        <rect x="0" y="0" width="18" height="18" fill="none" stroke="none" />
        <path fill="currentColor" fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>
        
    </Container>
    
      
      
    )
    
    }
    
    export default Buttonarrow;