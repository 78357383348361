import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import useContentful from "../../../utils/useContentful";
import BoldContentful from "../../../utils/BoldContentful";
import Herocol from "../../../components/impacto/Herocol.js";
import Layout from "../../../components/Layout/Layout.js";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import { CustomBreadcrumb } from "../../../components/CustomBreadcrumb";

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ImpactoEntry = "2oAZfLN7xDHsGeWDVtd6pk";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Textogray = styled.div`
  text-align: left;
  max-width: 1440px;
  color: var(--gris);
  margin-top: 40px;
  padding: 0% 5%;

  h2 {
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  p {
    margin-top: 16px;
  }

  a {
    text-decoration: underline;
    font-weight: 700;
    color: var(--gris);
  }
  @media (max-width: 750px) {
    margin-top: 0px;
  }
`;

const Textohighlight = styled.div`
  text-align: center;
  max-width: 1440px;
  padding: 0% 5%;
  color: ${(p) => p.colorinput};
  font-size: 24px;
  font-weight: 700;
  margin-top: 40px;
`;

const Divvideo = styled.div`
  max-width: 1440px;
  margin-top: 40px;
  width: 100%;
  iframe {
    width: 80%;
    height: auto;
    aspect-ratio: 1.7;
    position: relative;
    left: 10%;
  }

  @media (max-width: 750px) {
    iframe {
      width: 80%;
      height: auto;
      aspect-ratio: 1.7;
      position: relative;
    }
  }
`;

const Divimage = styled.div`
  width: 100%;
  text-align: center;
  max-width: 1440px;
  //width:60%;

  img {
    max-width:100%;
    width: ${(p) => (p.banner.bannerimage ? "100%" : "auto")} !important;
    height: ${(p) =>
      p.banner.bannerimage
        ? "auto"
        : p.banner.heighImage + "px"}!important;
    margin-top: ${(p) => (p.banner.bannerimage ? "25px" : "40px")};
  }
  a {
    cursor: pointer;
  }

  @media (max-width: 750px) {
    img {
      object-fit: contain;
      width: ${(p) => (p.banner ? "95%" : "90%")};
      //height: ${(p) => (p.banner ? "auto" : "auto")};
      max-height: 200px !important;
    }
  }
`;

const Imagewide = styled.img`
  width: 50%;
`;

const Articulopage = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;

  @media (max-width: 750px) {
    flex-direction: column-reverse;
  }
`;

const Autorpage = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  color: var(--gris);
  padding: 20px;
  margin-top: 0px;
  img {
    padding: 10px;
    width: 100%;
  }

  h2 {
    font-weight: 700;
  }
  @media (max-width: 750px) {
    width: 100%;
    align-items: center;
    margin-top: 30px;
    img {
      width: 50%;
    }
  }
`;

const Contentpage = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const links = [
  { name: "Impacto", to: "/impacto" },
  { name: "Columnas", to: "/impacto/columnas" },
];

const Colpage = (props) => {
  const [datapage, setDatapage] = useState([]);
  const [isarticle, setIsarticle] = useState(false);
  let id_ = props.params;

  const { data: impacto, fetched } = useContentful({ id: ImpactoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  async function f1() {
    if (id_ && id_.id.includes(".")) {
      const newid = id_.id.replaceAll(".", "?");

      id_.id = newid;
    }

    if (impacto != null) {
      let indpage = await impacto.fields.columnas.filter(
        (ind) =>
          ind.fields.slug
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
            .replace(/ *\([^)]*\) */g, "")
            .toLowerCase() == id_.id.replaceAll("--", " ")
      );

      setDatapage(indpage);

      //
    }
  }

  useEffect(() => {
    f1();
  }, [impacto]);

  const adaptContent = (pr) => {
    if (pr.sys.contentType.sys.id == "impactoColumnaText") {
      return (
        <Textogray>{BoldContentful(pr.fields.text, "var(--gris)")}</Textogray>
      );
    }

    if (pr.sys.contentType.sys.id == "impactoColumnaLongImage") {
 
      return (
        <Divimage banner={pr.fields}>
          {pr.fields.bannerimage ? (
           (pr.fields.image.fields && <a href={pr.fields.bannerLink} target="_blank">
              <img
                src={pr.fields.image.fields.file.url}
                alt={pr.fields.bannerAlt}
              />
            </a>)
          ) : (
            pr.fields.image.fields && <img
              src={pr.fields.image.fields.file.url}
              alt={pr.fields.bannerAlt}
            />
          )}
        </Divimage>
      );
    }

    if (pr.sys.contentType.sys.id == "impactoColumnaHighlight") {
      let choseencolor = pr.fields.colores;

      const colorpalete = [
        {
          name: "morado-celeste",
          first: "var(--morado)",
          second: "var(--celeste)",
        },
        {
          name: "magenta-celeste",
          first: "var(--magenta)",
          second: "var(--celeste)",
        },
        {
          name: "verde-morado",
          first: "var(--verde)",
          second: "var(--morado)",
        },
        {
          name: "celeste-naranja",
          first: "var(--celeste)",
          second: "var(--naranja)",
        },
        {
          name: "verde-celeste",
          first: "var(--verde)",
          second: "var(--celeste)",
        },
      ];

      let arraycolor = colorpalete.filter((el) => el.name === choseencolor);

      return (
        <Textohighlight colorinput={arraycolor[0].first}>
          {BoldContentful(pr.fields.texto, arraycolor[0].second)}
        </Textohighlight>
      );
    }

    if (pr.sys.contentType.sys.id == "impactoColumnaVideo") {
      return (
        <Divvideo>
          <iframe
            width="560"
            height="315"
            src={pr.fields.urlvideo}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Divvideo>
      );
    }
  };

  const whitePage = (props) => {
    return (
      <Layout
        margen={false}
        direccion={general.fields.direccion}
        facebook={general.fields.facebook}
        instagram={general.fields.instagram}
        linkedin={general.fields.linkedin}
        email={general.fields.email}
        telefono={general.fields.telefono}
        youtube={general.fields.youtube}
      >
        <MetaTags>
          <title>{props[0].fields.seoTitle || props[0].fields.titulo}</title>
          <meta
            name="description"
            content={
              props[0].fields.seoDescription || props[0].fields.cardDescription
            }
          />
          <meta name="robots" content="index,follow" />
          <link
            rel="alternate"
            hrefLang="es-PE"
            href={`https://www.aporta.org.pe/impacto/columna/${id_.id}`}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`https://www.aporta.org.pe/impacto/columna/${id_.id}`}
          />
          <link
            rel="canonical"
            href={`https://www.aporta.org.pe/impacto/columna/${id_.id}`}
          />
        </MetaTags>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Aporta",
                  item: "https://www.aporta.org.pe/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Impacto",
                  item: "https://www.aporta.org.pe/impacto/",
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: "Columna",
                  item: "https://www.aporta.org.pe/impacto/columna/",
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: ` ${id_.id}`,
                  item: `https://www.aporta.org.pe/impacto/columna/${id_.id}`,
                },
              ],
            })}
          </script>
        </Helmet>
        <Container>
          <CustomBreadcrumb
            links={links}
            currentPage={props[0].fields.titulo}
          />
          <Herocol
            imagen={props[0].fields.heroimage.fields.file.url}
            title={props[0].fields.titulo}
            fixedheight={props[0].fields.fixedHeightHero}
          ></Herocol>
          {props[0].fields.content.map((ind) => adaptContent(ind))}
          <br></br>
          <br></br>
          <br></br>
        </Container>
      </Layout>
    );
  };

  const dateFormat = (date) => {
    let number = date.split("T");

    let parts = number[0].split(/[-|/]/);
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  };

  const articlePage = (props) => {
    return (
      <Layout
        margen={false}
        direccion={general.fields.direccion}
        facebook={general.fields.facebook}
        instagram={general.fields.instagram}
        linkedin={general.fields.linkedin}
        email={general.fields.email}
        telefono={general.fields.telefono}
        youtube={general.fields.youtube}
      >
        <MetaTags>
          <title>{props[0].fields.seoTitle || props[0].fields.titulo}</title>
          <meta
            name="description"
            content={
              props[0].fields.seoDescription || props[0].fields.cardDescription
            }
          />
          <meta name="robots" content="index,follow" />
          <link
            rel="alternate"
            hrefLang="es-PE"
            href={`https://www.aporta.org.pe/impacto/columna/${id_.id}`}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`https://www.aporta.org.pe/impacto/columna/${id_.id}`}
          />
          <link
            rel="canonical"
            href={`https://www.aporta.org.pe/impacto/columna/${id_.id}`}
          />
        </MetaTags>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Aporta",
                  item: "https://www.aporta.org.pe/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Impacto",
                  item: "https://www.aporta.org.pe/impacto/",
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: "Columna",
                  item: "https://www.aporta.org.pe/impacto/columna/",
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: ` ${id_.id}`,
                  item: `https://www.aporta.org.pe/impacto/columna/${id_.id}`,
                },
              ],
            })}
          </script>
        </Helmet>
        <Container>
          <CustomBreadcrumb
            links={links}
            currentPage={props[0].fields.titulo}
          />
          <Herocol
            imagen={props[0].fields.heroimage.fields.file.url}
            title={props[0].fields.titulo}
            fixedheight={props[0].fields.fixedHeightHero}
          ></Herocol>
          <Articulopage>
            <Autorpage>
              <img src={props[0].fields.authorPicture.fields.file.url} />
              <h2>{props[0].fields.authorName}</h2>
              <p>{props[0].fields.authorPosition}</p>
              {/* <p>{`Publicado el ${dateFormat(
                props[0].fields.fechaPublicacion
              )}`}</p>*/}
            </Autorpage>
            <Contentpage>
              {props[0].fields.content.map((ind) => adaptContent(ind))}
            </Contentpage>
          </Articulopage>
          <br></br>
          <br></br>
          <br></br>
        </Container>
      </Layout>
    );
  };


  return (
    <>
      {fetched && fetched2 && datapage.length ? (
        <>
          {!datapage[0].fields.pageWithAuthor ||
          datapage[0].fields.pageWithAuthor === false
            ? whitePage(datapage)
            : articlePage(datapage)}
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};
export default Colpage;
