import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful2 from "../../../../utils/useContentful2";
import MetaTags from "react-meta-tags";
import Titlerich from "../../../../components/Titlerich.js";
import { Link } from "gatsby";
import Historianorich from "../../../../components/proyectos/pais/Historianorich.js";
import Historianorichright from "../../../../components/proyectos/pais/Historianorichright.js";
import Sideimage from "../../../../components/proyectos/pais/Sideimage.js";
import Sliderphotos from "../../../../components/proyectos/breca/Sliderphotos.js";
import "../../../../styles/global.css";
import "../../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../../images/Home/home.png";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 160px;
    height: 100px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  img {
    width: 80%;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const Carddiv = styled.div`
  width: 170px;
  height: 291px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--morado);
  justify-content: space-evenly;
  align-items: center;
`;
const Icono = styled.img`
  height: 60px;
  width: 60px;
`;

const Porcentage = styled.div`
  width: 100%;
  text-align: center;
  color: var(--blanco);
  font-weight: 600;
  font-size: 24px;
`;

const Content = styled.div`
  color: var(--blanco);
  text-align: center;
  height: 30%;
`;
const Cardbig = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  max-width: 1440px;
  width: 80%;
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const Cardr = ({ cards }) => {

  return (
    <Cardbig>
      {cards.map((field, index) => {
        return (
          <Carddiv>
            <Porcentage> {field.fields.porcentaje}</Porcentage>
            <Icono src={field.fields.icono.fields.file.url}></Icono>
            <Content>{field.fields.titulo}</Content>
          </Carddiv>
        );
      })}
    </Cardbig>
  );
};

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

 
  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitleBreca[2]}</title>
            <meta
              name={proyectos.fields.metaDescriptionBreca[2]}
              content={proyectos.fields.metaContentBreca[2]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/breca/desenrollate/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/breca/desenrollate/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/breca/desenrollate/"
            />
          </MetaTags>

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social Breca`,
                    item: `https://www.aporta.org.pe/proyectos/breca/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Desafío Desenrróllate`,
                    item: `https://www.aporta.org.pe/proyectos/breca/desenrollate`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/breca"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social Breca
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/breca/desenrollate"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Desafío Desenrróllate
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--naranja"}
                colortext={"var(--negro)"}
                container={
                  proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                    .fields.tituloRich
                }
                colorbold={"var(--negro"}
              ></Titlerich>
              {/*<Pstyled >{proyectos.fields.breitSite.fields.subtitulo}</Pstyled>*/}
              <br></br>
              <Sideimage
                image={
                  proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                    .fields.propositoImagen.fields.file.url
                }
                colorb={"var(--naranja)"}
              >
                <Contentbar>
                  {
                    proyectos.fields.innovacinSocialBrecaSite.fields
                      .desenrollate.fields.propositoDescripcion
                  }
                </Contentbar>
              </Sideimage>
              <br></br>

              <br></br>
              <Historianorich
                texto={
                  proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                    .fields.desafioDesenrollateVirtualDescripcion
                }
                fontc={"var(--naranja)"}
                fontcb={"var(--naranja)"}
              >
                <Cardcontainer>
                  <img
                    src={
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.desafioDesenrollateVirtualImagen
                        .fields.file.url
                    }
                    alt="desenrrollate"
                  />
                </Cardcontainer>
              </Historianorich>

              <br></br>
              <br></br>
              {proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                .fields.banner.fields.bannermiddlePage ? (
                <Link
                  to={
                    proyectos.fields.innovacinSocialBrecaSite.fields
                      .desenrollate.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.banner.fields.bannerImage.fields
                        .file.url
                    }
                    alt={
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
              <br></br>
              <br></br>

              <Historianorichright
                texto={
                  proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                    .fields.quelogramosDescripcion
                }
                fontc={"var(--naranja)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.quelogramosImagen.fields.file.url
                    }
                    alt="desenrrollate imagen"
                  />
                </Cardcontainer>
              </Historianorichright>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Sliderphotos
                galeria={
                  proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                    .fields.testimonios
                }
              ></Sliderphotos>
              <br></br>
              <br></br>
              {proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                .fields.banner.fields.bannerbottomPage ? (
                <Link
                  to={
                    proyectos.fields.innovacinSocialBrecaSite.fields
                      .desenrollate.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.banner.fields.bannerImage.fields
                        .file.url
                    }
                    alt={
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
