import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    margin: 30px 0px;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: var(--blanco);
  top: 0px;

  @media (max-width: 900px) {
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    top: 40px;
    display: ${(p) => (p.act ? "flex" : "none")};
  }
`;

const Item = styled(Link)`
  padding: 0px 20px;
  font-size: 24px;
  text-decoration: ${(p) => (p.active ? "underline" : "none")};
  color: ${(p) => (p.active ? "var(--magenta)" : "var(--negro)")};
  font-weight: ${(p) => (p.active ? "600" : "400")};
  text-underline-offset: 12px;
  border-right: 1px solid var(--gris);
  cursor: pointer;

  :nth-child(1) {
    color: ${(p) => (p.active ? "var(--celeste)" : "var(--negro)")};
  }

  :nth-child(2) {
    color: ${(p) => (p.active ? "var(--celeste)" : "var(--negro)")};
  }

  :nth-child(3) {
    border-right: 0px solid var(--gris);
    color: ${(p) => (p.active ? "var(--celeste)" : "var(--negro)")};
  }

  @media (max-width: 900px) {
    border-right: 0px solid var(--gris);
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    background-color: var(--blanco);
    text-decoration: none;
    width: 100%;
    :hover {
      background-color: var(--grisclaro);
    }
  }
`;

const Buttondiv = styled.div`
  display: none;

  svg {
    position: absolute;
    right: 40px;
  }

  @media (max-width: 900px) {
    display: flex;
    width: 100%;
    border-bottom: 2px solid var(--grisclaro);
    justify-content: center;
    padding-bottom: 4px;
    flex-direction: row;
  }
`;

const Buttondown = () => {
  const url = typeof window !== "undefined" ? window.location.href : "";
 
  let pagina = url.split("/", 7);

  let seccion = pagina.slice(-1);
  if (seccion == "evidencia") {
    return (
      <Buttondiv>
        Data y Evidencia
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="2em"
          height="1.5em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 20 20"
        >
          <rect x="0" y="0" width="20" height="20" fill="none" stroke="none" />
          <path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z" />
        </svg>
      </Buttondiv>
    );
  }
  if (seccion == "compromiso") {
    return (
      <Buttondiv>
        Nuestro Compromiso
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="2em"
          height="1.5em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 20 20"
        >
          <rect x="0" y="0" width="20" height="20" fill="none" stroke="none" />
          <path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z" />
        </svg>
      </Buttondiv>
    );
  }
  if (seccion == "metodologia") {
    return (
      <Buttondiv>
        Metodología
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="2em"
          height="1.5em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 20 20"
        >
          <rect x="0" y="0" width="20" height="20" fill="none" stroke="none" />
          <path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z" />
        </svg>
      </Buttondiv>
    );
  }
  if (seccion == "casos") {
    return (
      <Buttondiv>
        Casos de Éxito
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="2em"
          height="1.5em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 20 20"
        >
          <rect x="0" y="0" width="20" height="20" fill="none" stroke="none" />
          <path fill="#9e9e9e" d="m5 6l5 5l5-5l2 1l-7 7l-7-7z" />
        </svg>
      </Buttondiv>
    );
  }
};

const Navegationevidencia = (props) => {
  const [visible, Setvisible] = useState(false);

  const handleVisible = () => {
    Setvisible(!visible);
  };

  return (
    <Container>
      <div style={{ width: "100%" }} onClick={() => handleVisible()}>
        {" "}
        <Buttondown></Buttondown>{" "}
      </div>

      <Dropdown act={visible}>
        <Item
          to="/metodologias/evidencia/compromiso"
          active={props.selection === "1"}
        >
          Nuestro Compromiso
        </Item>
        <Item
          to="/metodologias/evidencia/metodologia"
          active={props.selection === "2"}
        >
          Metodología
        </Item>
        <Item
          to="/metodologias/evidencia/casos"
          active={props.selection === "3"}
        >
          Casos de Éxito
        </Item>
      </Dropdown>
    </Container>
  );
};

export default Navegationevidencia;
