import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
width:100%;
display:flex;
height:500px;
justify-content:center;
position:relative;
margin:0px ;

@media (max-width: 900px) {
  height:auto;
  margin-top:48px ;
}

`

const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;
height: 100%;
display: flex;
justify-content:center;
flex-direction: row ;
position: relative;

@media (max-width: 900px) {
  width: 100%;
  flex-direction: column ;
}
`

const Barcolo= styled.div`
position:absolute;
width: 100%;
height:374px;
top:63px;
background-color:var(--magenta);
z-index:0;


@media (max-width: 900px) {
  height:100%; 
}
`

const Image = styled.div`
display: flex;
width: 50%;
padding:2%;
z-index:1;
img{width:100%;}

@media (max-width: 900px) {
  width: 100%;
  padding: 0px 30px;

}

`

const Content = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
width: 50%;
height: 374px;
top:63px;
padding:2%;
z-index:2;
color: var(--blanco);

@media (max-width: 900px) {

  width:100% ;
  height: auto;
  padding:0px 30px ;
  top:30px;
}
`


const Sideimage = ({image,left ,  colorb , children}) => {

    return(
     
        <Container  >
            <Subcontainer>
              <Image left={left}><img src={image} alt="imagen historia 2"></img>  </Image>
                    <Content left={left}>
                 {children}
    </Content>          
        </Subcontainer>
        <Barcolo clr={colorb}>
            </Barcolo>
    </Container>
      
    )
    }
    export default Sideimage;