import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Titlerich from "../Titlerich.js";
import Button from "../Boton.js";
import Buttonarrow from "../Botonarrow.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: 550px;
  padding: 0px 0px;
  margin-bottom: 50px;
  margin-top: 60px;
  z-index: 1;

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  .react-multi-carousel-dot button {
    border-color: var(--celeste);
    background-color: var(--blanco);
  }

  .react-multi-carousel-dot--active {
    button {
      background: var(--celeste);
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const ButtonStyled = styled.a`
  position: absolute;
  z-index: 1;
  right: 25px;
  bottom: 224px;
`;

const Container = styled.div`
  width: 100%;
  height: 915px;
  background-color: var(--blanco);
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 91px;
  flex-direction: column;

  @media (max-width: 900px) {
    width: calc(100vw - 60px);
    margin-left: 30px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Cards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CardContainer = styled.div`
  position: relative;
  width: 384px;
  height: 465px;
  display: flex;
  flex-direction: column;
`;
const Foto = styled.div`
  height: 46%;
  width: 100%;
  background-image: url(${(p) => p.fotourl});
  border-radius: 4px;
  background-size: cover;

  :hover {
    box-shadow: 0px 0px 40px rgba(83, 202, 236, 0.7);
  }
`;

const Lateral = styled.div`
  position: absolute;
  font-size: 120px;
  font-weight: 1000;
  transform: rotate(-90deg);
  -webkit-text-stroke: 2px var(--naranja);
  color: var(--blanco);
  opacity: 0.15;
  bottom: 312px;
  left: -166px;
  z-index: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;

  svg {
    margin-right: 4px;
  }
`;

const Texto = styled.div`
  height: 54%;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 800;
    margin-top: 32px;
    margin-bottom: 6px;
  }

  p {
    color: var(--gris);
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const Card = (props) => {
  var tourl = [];

  tourl = `/impacto/publicacion/${props.url
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
    .replace(/ *\([^)]*\) */g, "")
    .toLowerCase()
    .replaceAll(" ", "-")
    
  }`;

 

  return (
    <CardContainer>
      <Foto fotourl={props.imagen}></Foto>
      <Texto>
        <h2>{props.titulo}</h2>

        {/*<Row>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 12.5C7.5 12.9602 7.1269 13.3333 6.66667 13.3333C6.20643 13.3333 5.83333 12.9602 5.83333 12.5C5.83333 12.0398 6.20643 11.6667 6.66667 11.6667C7.1269 11.6667 7.5 12.0398 7.5 12.5Z" fill="#9E9E9E"/>
<path d="M7.5 9.16667C7.5 9.6269 7.1269 10 6.66667 10C6.20643 10 5.83333 9.6269 5.83333 9.16667C5.83333 8.70643 6.20643 8.33333 6.66667 8.33333C7.1269 8.33333 7.5 8.70643 7.5 9.16667Z" fill="#9E9E9E"/>
<path d="M10.8333 12.5C10.8333 12.9602 10.4602 13.3333 10 13.3333C9.53976 13.3333 9.16667 12.9602 9.16667 12.5C9.16667 12.0398 9.53976 11.6667 10 11.6667C10.4602 11.6667 10.8333 12.0398 10.8333 12.5Z" fill="#9E9E9E"/>
<path d="M10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667Z" fill="#9E9E9E"/>
<path d="M14.1667 9.16667C14.1667 9.6269 13.7936 10 13.3333 10C12.8731 10 12.5 9.6269 12.5 9.16667C12.5 8.70643 12.8731 8.33333 13.3333 8.33333C13.7936 8.33333 14.1667 8.70643 14.1667 9.16667Z" fill="#9E9E9E"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M10 3.95833C8.23611 3.95833 5.8262 4.11118 4.32208 4.22123C3.82708 4.25745 3.43704 4.64426 3.39684 5.13801C3.28083 6.56312 3.125 8.78477 3.125 10.4167C3.125 12.0486 3.28083 14.2702 3.39684 15.6953C3.43704 16.1891 3.82708 16.5759 4.32208 16.6121C5.8262 16.7222 8.23611 16.875 10 16.875C11.7639 16.875 14.1738 16.7222 15.6779 16.6121C16.1729 16.5759 16.563 16.1891 16.6032 15.6953C16.7192 14.2702 16.875 12.0486 16.875 10.4167C16.875 8.78477 16.7192 6.56312 16.6032 5.13801C16.563 4.64426 16.1729 4.25745 15.6779 4.22123C14.1738 4.11118 11.7639 3.95833 10 3.95833ZM4.23087 2.97457C5.73567 2.86446 8.18596 2.70833 10 2.70833C11.814 2.70833 14.2643 2.86446 15.7691 2.97457C16.8778 3.05568 17.7588 3.92773 17.849 5.03659C17.9651 6.46282 18.125 8.72915 18.125 10.4167C18.125 12.1042 17.9651 14.3705 17.849 15.7967C17.7588 16.9056 16.8778 17.7777 15.7691 17.8588C14.2643 17.9689 11.814 18.125 10 18.125C8.18596 18.125 5.73567 17.9689 4.23087 17.8588C3.12225 17.7777 2.24123 16.9056 2.15096 15.7967C2.03486 14.3705 1.875 12.1042 1.875 10.4167C1.875 8.72915 2.03486 6.46282 2.15096 5.03659C2.24123 3.92773 3.12225 3.05568 4.23087 2.97457Z" fill="#9E9E9E"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M7.5 1.875C7.84518 1.875 8.125 2.15482 8.125 2.5V4.58333C8.125 4.92851 7.84518 5.20833 7.5 5.20833C7.15482 5.20833 6.875 4.92851 6.875 4.58333V2.5C6.875 2.15482 7.15482 1.875 7.5 1.875Z" fill="#9E9E9E"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M12.5 1.875C12.8452 1.875 13.125 2.15482 13.125 2.5L13.125 4.58333C13.125 4.92851 12.8452 5.20833 12.5 5.20833C12.1548 5.20833 11.875 4.92851 11.875 4.58333L11.875 2.5C11.875 2.15482 12.1548 1.875 12.5 1.875Z" fill="#9E9E9E"/>
</svg>
<p> {props.fecha}</p>
</Row>*/}
        <p>{props.content}</p>
      </Texto>
      <ButtonStyled href={tourl}>
        {" "}
        <Buttonarrow></Buttonarrow>
      </ButtonStyled>
    </CardContainer>
  );
};

const Noticias = ({ titulo, card, boton }) => {

  return (
    <Container>
      <Titlerich
        container={titulo}
        color="var(--naranja)"
        colortext="#000000"
        colorbold="var(--negro)"
      ></Titlerich>
      <Subcontainer>
        <CarouselStyled
          arrows={false}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}
          showDots
        >
          {card.map((field, index) => {
            return (
              <Card
                content={field.fields.descripcion}
                
                imagen={field.fields.image.fields.file.url}
                titulo={field.fields.titulo}
                url={field.fields.slug}
              ></Card>
            );
          })}
        </CarouselStyled>
      </Subcontainer>
      <Button enlace="/impacto/columnas">{boton}</Button>
      <Lateral>NOTICIAS</Lateral>
    </Container>
  );
};

export default Noticias;
