import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful3 from "../../../../utils/useContentful3";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Navegationevidencia from "../../../../components/comotrabajamos/evidencia/navegationevidencia.js";
import "../../../../styles/global.css";
import { Helmet } from "react-helmet";
import { CustomBreadcrumb } from "../../../../components/comotrabajamos/evidencia/CustomBreadcrumb.js";
import Herotitle from "../../../../components/comotrabajamos/evidencia/Herotitle.js"
import Historianorich from "../../../../components/comotrabajamos/evidencia/Historianorich.js"
import Sideimagelargevolar from "../../../../components/comotrabajamos/evidencia/Sideimagelargevolar.js";
import Button from "../../../../components/comotrabajamos/evidencia/Boton.js";
import TextMidContainer from "../../../../components/comotrabajamos/evidencia/Textrichcrecerintro.js";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Titlecontainer = styled.div`
  background-color: ${(p) => p.bcolor};
  padding: 0 5px;
  text-align: center;
  
  span {
    display: inline-block;
    color: ${(p) => p.co};
    font-size: 38px;
    font-weight: 700;
    
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }
`;

const Videocontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  iframe {
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    iframe {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const TituloSeccion = styled.div`
  color: #FFFFFF;
  font-size: 42px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: center;
  background-color: var(--celeste);
  padding: 0 5px;
  transform: skewX(-10deg);
  @media (max-width: 900px) {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 90%;
  }
`;

const Titulodiv = styled.div`
  color: var(--naranja);
  font-size: 38px;
  margin-bottom: 20px;
  font-weight: 700;
  width: 100%;
  text-align: left;
  @media (max-width: 900px) {
    width: calc(100% - 60px);
    font-size: 24px;
  }
`;

const Titulodiv2 = styled.div`
  color: var(--celeste);
  font-size: 38px;
  margin-bottom: 20px;
  font-weight: 700;
  width: 100%;
  text-align: left;
  @media (max-width: 900px) {
    width: calc(100% - 60px);
    font-size: 24px;
  }
`;

const Textodiv = styled.div`
  color: var(--gris);
  width: 100%;
  text-align: justify;
  @media (max-width: 900px) {
    width: calc(100% - 60px);
  }
`;


const links = [
  { name: "Cómo trabajamos", to: "/metodologias" },
  { name: "Área de Data y Evidencia", to: "/metodologias/evidencia" }
];

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const DataEvEntryId = "5kXlxEnljR82TtlDhkyiZh";

const IndexPage = ({ location }) => {

  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  const { data: dataevidence, fetched: fetched } = useContentful3({
    id: DataEvEntryId,
  });

  const paragraphs2 = dataevidence?.fields.seccionText7.split('\n');
  const paragraphs4 = dataevidence?.fields.seccionText9.split('\n');

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{"Casos de Éxito"}</title>
            <meta
              name={"Casos de Éxito"}
              content={"Casos de Éxito"}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/metodologias/evidencia/casos"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/metodologias/evidencia/casos"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/metodologias/evidencia/casos"
            />
          </MetaTags>

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/metodologias/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social País`,
                    item: `https://www.aporta.org.pe/metodologias/evidencia/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Data y Evidencia`,
                    item: `https://www.aporta.org.pe/metodologias/evidencia/casos`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <CustomBreadcrumb links={links} currentPage="Casos de Éxito" />
              <br></br>
              {dataevidence.fields.backgroundImage.fields.file.url && dataevidence.fields.mainTitle && dataevidence.fields.subtext1 && dataevidence.fields.subtext2 ? (
              <Herotitle
                imagen={dataevidence.fields.backgroundImage.fields.file.url}
                titulo={dataevidence.fields.mainTitle}
                texto={dataevidence.fields.subtext1}
              ></Herotitle>):(<></>)}
              <Pstyled>{""}</Pstyled>
              <TextMidContainer
                texto={dataevidence.fields.subtext2}
              ></TextMidContainer>
              <br></br>
              <br></br>
              <Navegationevidencia selection={"3"}></Navegationevidencia>
              <br></br>
              <br></br>
                <>
                  <Titlecontainer co={"#FFFFFF"} bcolor={"#53CAEC"}>
                    <span>{dataevidence?.fields?.seccionMainTitle6 || null}</span>
                  </Titlecontainer>
                  <br></br>
                  <br></br>
                  {dataevidence.fields.seccionTitle6 && dataevidence.fields.seccionText6 && dataevidence.fields.urlSeccion6 ? (
                    <Historianorich
                    title={dataevidence.fields.seccionTitle6}
                    texto={dataevidence.fields.seccionText6}
                    texto2={dataevidence.fields.seccionText62}
                    fontc={"var(--celeste)"}
                    fontcb={"var(--celeste)"}
                    >
                    <Videocontainer>
                      <iframe
                        width="560"
                        height="400"
                        src={dataevidence.fields.urlSeccion6}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </Videocontainer>
                    </Historianorich>
                    ):(<></>)}
                  {dataevidence.fields.seccionImage7.fields.file.url && dataevidence.fields.seccionTitle7 && dataevidence.fields.seccionText7 ? (
                  <Sideimagelargevolar
                  image={dataevidence.fields.seccionImage7.fields.file.url}
                      >
                  <Titulodiv2>
                    {dataevidence.fields.seccionTitle7}
                  </Titulodiv2>
                  <Textodiv>
                    {paragraphs2.map((paragraph, index) => (
                        <p key={index} style={{ marginBottom: '1em' }}>
                          {paragraph}
                        </p>
                      ))}
                  </Textodiv>
                  </Sideimagelargevolar>
                  ):(<></>)}
                <br/>
                  <Button
                        enlace={"/proyectos/pais/volar"}
                      >
                        Ver más
                  </Button>
                </>
                <br/>
                <br/>
                <>
                  <Titlecontainer co={"#FFFFFF"} bcolor={"#FF9F24"}>
                    <span>{dataevidence?.fields?.seccionMainTitle8 || null}</span>
                  </Titlecontainer>
                  <br/>
                  <br/>
                  {dataevidence.fields.seccionTitle8 && dataevidence.fields.seccionText8 && dataevidence.fields.urlSeccion8 ? (
                    <Historianorich
                    title={dataevidence.fields.seccionTitle8}
                    texto={dataevidence.fields.seccionText8}
                    texto2={dataevidence.fields.seccionText82}
                    fontc={"var(--naranja)"}
                    fontcb={"var(--naranja)"}
                  >
                    <Videocontainer>
                      <iframe
                        width="560"
                        height="400"
                        src={dataevidence.fields.urlSeccion8}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </Videocontainer>
                  </Historianorich>
                    ):(<></>)}
                  {dataevidence.fields.seccionImage9.fields.file.url && dataevidence.fields.seccionTitle9 && dataevidence.fields.seccionText9 ? (
                  <Sideimagelargevolar
                  image={dataevidence.fields.seccionImage9.fields.file.url}
                      >
                  <Titulodiv>
                    {dataevidence.fields.seccionTitle9}
                  </Titulodiv>
                  <Textodiv>
                  {paragraphs4.map((paragraph, index) => (
                        <p key={index} style={{ marginBottom: '1em' }}>
                          {paragraph}
                        </p>
                      ))}
                  </Textodiv>
                  </Sideimagelargevolar>
                  ):(<></>)}
                  <br/>
                  <Button
                        enlace={"/impacto/publicacion/proyecto-familias-saludables"}
                      >
                        Ver más
                  </Button>
                </>
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
