import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful";
import MetaTags from "react-meta-tags";
import Hero from "../../../components/impacto/Hero.js";
import Navegation from "../../../components/impacto/Navegation.js";
import Buttonarrow from "../../../components/Botonarrow.js";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "../../../styles/global.css";
import { Helmet } from "react-helmet";
import Home from "../../../images/Home/home.png";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Divsearch = styled.div`
  width: 400px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Text = styled.div`
  width: 100%;
`;
const Cardcontent = styled.div`
  width: 90%;
  max-width: 1440px;
  display: grid;
  //grid-row-start: 1fr 1fr ;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  row-gap: 50px;
  justify-content: center;
  position: relative;
  z-index: 1;
  justify-items: center;

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 25px;
    column-gap: 16px;
    padding: 0px 30px;
  }
`;

const Cardcontainer = styled.div`
  position: relative;
  width: 384px;
  height: 465px;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Foto = styled.div`
  height: 46%;
  width: 100%;
  background-image: url(${(p) => p.fotourl});
  border-radius: 4px;
  background-size: cover;

  :hover {
    box-shadow: 0px 0px 40px rgba(83, 202, 236, 0.7);
  }
`;

const Texto = styled.div`
  height: 54%;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 800;
    margin-top: 32px;
    margin-bottom: 6px;
  }

  p {
    color: var(--gris);
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;

  svg {
    margin-right: 4px;
  }
`;

const ButtonStyled = styled(Link)`
  position: absolute;
  z-index: 1;
  right: 25px;
  bottom: 224px;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const Card = (props) => {
  // const [slug , setSlug] = useState("");
  
  let slug = "";
  if (props?.url.includes("(")) {
    const newurl = props?.url.replace(/ *\([^)]*\) */g, "");
    slug = newurl
      .replaceAll(" ", "--")
      .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  } else {
    slug = props?.url
      .replaceAll(" ", "--")
      .normalize("NFD")
      .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  //let slug = props?.url.replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

  return (
    <Cardcontainer>
      <Foto fotourl={props.imagen}></Foto>
      <Texto>
        <h2>{props.titulo}</h2>

        <p>{props.content}</p>
      </Texto>
      <ButtonStyled to={`/impacto/columna/${slug}`}>
        {" "}
        <Buttonarrow></Buttonarrow>
      </ButtonStyled>
    </Cardcontainer>
  );
};

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ImpactoEntry = "2oAZfLN7xDHsGeWDVtd6pk";

const IndexPage = ({ location }) => {
  const { data: impacto, fetched } = useContentful({ id: ImpactoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });
  const [searchword, Setsearchword] = useState("");
  const [datavisible, Setdatavisible] = useState([]);
  const [titles, Settitles] = useState([]);

  // titulo={adopcion.fields.heroTitulo} prop example

  const handleOnSearch = (string, results) => {
  
    Setdatavisible([]);
  };

  const handleOnHover = (result) => {
   
  };

  const handleOnSelect = (item) => {
  
    Setsearchword(item);
  };

  const handleOnFocus = () => {
   
  };


  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {item.value}
        </span>
      </>
    );
  };

  useEffect(() => {
    const arraytitle = [];
    impacto?.fields.columnas.map((item) => {
    
      arraytitle.push({ name: item.fields.titulo, value: item.fields.titulo });
      Settitles(arraytitle);
    });

  
  }, [impacto]);

  useEffect(() => {
    if (impacto) {
      
      let filterdata = impacto?.fields.columnas.filter((item) => {
        
        return item.fields.titulo === searchword.name;
      });

      Setdatavisible(filterdata);
    
    }
  }, [searchword]);

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title> {impacto.fields.pageTitle[4]} </title>
            <meta
              name={impacto.fields.metaDescription[4]}
              content={impacto.fields.metaContent[4]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/impacto/columnas/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/impacto/columnas/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/impacto/columnas/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Impacto",
                    item: "https://www.aporta.org.pe/impacto/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: "Columnas",
                    item: "https://www.aporta.org.pe/columnas/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Hero
                imagen={impacto.fields.headerImagenDeFondo.fields.file.url}
              ></Hero>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/impacto")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/impacto"
                  className={
                    location.pathname.startsWith("/impacto")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Impacto
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/impacto/columnas"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Columnas
                </Link>
              </Breadcrumbs>
              <Navegation selection={"4"}></Navegation>
              <br></br>
              <Divsearch>
                <ReactSearchAutocomplete
                  items={titles}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  formatResult={formatResult}
                  styling={{ zIndex: 4 }}
                  placeholder={"Buscar la publicación de tu interes"}
                />
              </Divsearch>
              <br></br>
              <Cardcontent>
                {datavisible.length === 0 &&
                  impacto.fields.columnas.map((field, index) => {
                    return (
                      <Card
                        content={field.fields.cardDescription}
                        imagen={field.fields.cardImage.fields.file.url}
                        titulo={field.fields.titulo}
                        url={field.fields.slug}
                      ></Card>
                    );
                  })}

                {datavisible.length !== 0 &&
                  datavisible?.map((field, index) => {
                    return (
                      <Card
                        content={field.fields.cardDescription}
                        imagen={field.fields.cardImage.fields.file.url}
                        titulo={field.fields.titulo}
                        url={field.fields.slug}
                      ></Card>
                    );
                  })}
              </Cardcontent>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
