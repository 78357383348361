import * as React from "react";
import styled from "styled-components";


const Container = styled.div`
width:100%;
display:flex;
height:650px;
justify-content:center;
@media (max-width: 900px) {
    height:auto;
}

`

const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;
display: flex;
justify-content:center;
flex-direction: row ;

@media (max-width: 900px) {
  width: calc(100% - 60px);
  flex-direction: column-reverse;
}
`

const Text = styled.div`
width: 100%;

:nth-child(1) {
    color:var(--magenta);
    font-weight:800;
}

`



const Image = styled.div`
width: 50%;
padding:7% 5% 5% 5% ;
margin:0px;
img{
    width: 100%;
    padding:0% ;
}

@media (max-width: 900px) {
    width: 100%;
    padding:0% ;
    margin-bottom:24px ;
    margin-top:40px ;
}

`

const Textcontainer = styled.div `
display: flex;
flex-direction: column;
width: 50%;
padding: 0px 7% ;
color: var(--gris);
justify-content:center;

@media (max-width: 900px) {
    width: 100%;
    padding: 0px;
}


`

const Paragraph = ({content}) => {

return(
    <>
    {
        content.map((t , index)=>{
            return(
            <Text>{t.value} </Text>
            
            );
        }
    )
}
<br></br>
    </>
)

}


const Historia = ({text , image}) => {

    return(
     
        <Container  >
            <Subcontainer>
<Textcontainer>
                {text.map((field,index)=>{
                    return(
                    <Paragraph content ={field.content}></Paragraph>
                    );
                }
                )
                }
</Textcontainer>
                <Image>
                    <img src={image} alt=" historia"/>
                </Image>
            </Subcontainer>
    </Container>
    )
    }
    
    export default Historia;