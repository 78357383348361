import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

const Container = styled.div`
padding:8px 31px;
background-color:${p=>p.col || "var(--celeste)" };
color:var(--blanco);
border-radius:4px;
display: inline-block;
font-weight: 600;
z-index:${p=>p.z || 1} ;
a{
    font-size: 24px;
   color:var(--blanco);
   text-decoration: none; 
}

:hover{
    
    background-color:${p=>p.col || "#47aecb"}; 
    cursor: pointer;
}
@media (max-width: 768px){
    padding:4px 24px;
    a{
        font-size: 16px;
   color:var(--blanco);
   text-decoration: none; 
  
}
}

`

const Button2 = ({ children , enlace, zindex , bckcol}) => {

    return(
     
        <Container z = {zindex} col ={bckcol}>
           <Link to ={enlace}>
            {children}
           
            </Link>
    </Container>
    
      
      
    )
    
    }
    
    export default Button2;