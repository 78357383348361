import * as React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful";
import useContentful2 from "../../../utils/useContentful2";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Titlerich from "../../../components/Titlerich.js";
import Historia from "../../../components/proyectos/breit/Historia.js";
import Historia2 from "../../../components/proyectos/breit/Historia2.js";
import Sideimage from "../../../components/proyectos/pais/Sideimage.js";
import Bottomsection from "../../../components/proyectos/breit/Bottomsection.js";
import Logos from "../../../components/proyectos/breit/Logos.js";
import "../../../styles/global.css";
import "../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../images/Home/home.png";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 160px;
    height: 100px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: auto;
  margin-bottom: 0px;
  @media (max-width: 900px) {
    overflow: hidden;
  }

  img {
    width: 80%;
  }

  @media (max-width: 900px) {
    img {
      width: 140%;
      margin-bottom: 20px;
    }
  }
`;

const Titulo = styled.div`
  width: 90%;
  max-width: 1440px;
  font-size: 24px;
  color: var(--gris);
  font-weight: 700;
  padding: 0px 4%;
  display: flex;
  justify-content: center;
`;

const Linebar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });


  // titulo={adopcion.fields.heroTitulo} prop example
  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitleBreit}</title>
            <meta
              name={proyectos.fields.metaDescriptionBreit}
              content={proyectos.fields.metaContentBreit}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/breit"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/breit"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/breit"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `BREIT`,
                    item: `https://www.aporta.org.pe/proyectos/breit/`,
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/breit"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  BREIT
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--magenta"}
                colortext={"var(--negro)"}
                container={proyectos.fields.breitSite.fields.tituloRich}
                colorbold={"var(--negro"}
              ></Titlerich>
              <Pstyled>{proyectos.fields.breitSite.fields.subtitulo}</Pstyled>
              <br></br>
              <Sideimage
                image={
                  proyectos.fields.breitSite.fields.propositoImagen.fields.file
                    .url
                }
                colorb={"var(--verde)"}
              >
                <Contentbar>
                  <Linebar>
                    <img
                      src={
                        proyectos.fields.breitSite.fields.propositos[0].fields
                          .icono.fields.file.url
                      }
                      alt="breit"
                    />
                    {
                      proyectos.fields.breitSite.fields.propositos[0].fields
                        .proposito
                    }
                  </Linebar>
                  <Linebar>
                    <img
                      src={
                        proyectos.fields.breitSite.fields.propositos[1].fields
                          .icono.fields.file.url
                      }
                      alt="breit 2"
                    />
                    {
                      proyectos.fields.breitSite.fields.propositos[1].fields
                        .proposito
                    }
                  </Linebar>
                </Contentbar>
              </Sideimage>
              <br></br>
              <Titlerich
                color={"var(--blanco"}
                colortext={"var(--negro)"}
                container={proyectos.fields.breitSite.fields.subtituloRich}
                colorbold={"var(--negro"}
              ></Titlerich>
              <br></br>
              <Historia
                titulo={
                  proyectos.fields.breitSite.fields.programa1.fields
                    .seccion1Titulorich
                }
                texto={
                  proyectos.fields.breitSite.fields.programa1.fields
                    .seccion1Descripcion
                }
                fontc={"var(--verde)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={
                      proyectos.fields.breitSite.fields.programa1.fields
                        .seccion1Imagen.fields.file.url
                    }
                    alt="breit3"
                  />
                </Cardcontainer>
              </Historia>

              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <Historia2
                titulo={
                  proyectos.fields.breitSite.fields.programa1.fields
                    .seccion2Titulorich
                }
                texto={
                  proyectos.fields.breitSite.fields.programa1.fields
                    .seccion2Descripcion
                }
                fontc={"var(--verde)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={
                      proyectos.fields.breitSite.fields.programa1.fields
                        .seccion2Imagen.fields.file.url
                    }
                    alt="breit4"
                  />
                </Cardcontainer>
              </Historia2>
              <br></br>
              {proyectos.fields.innovacionSocialPaisSite.fields
                .proyectoArcoirisSite.fields.banner.fields.bannerPage ? (
                <Link
                  to={
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoArcoirisSite.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerImage
                        .fields.file.url
                    }
                    alt={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
              <br></br>
              <Titulo>Nuestros partners</Titulo>
              <Logos
                cards={
                  proyectos.fields.breitSite.fields.programa1.fields.partners
                }
              ></Logos>
              <Titulo>ONGs aliadas</Titulo>
              <Logos
                cards={proyectos.fields.breitSite.fields.programa1.fields.ongs}
              ></Logos>

              <Historia
                titulo={
                  proyectos.fields.breitSite.fields.programa2.fields.titulorich
                }
                texto={
                  proyectos.fields.breitSite.fields.programa2.fields.descripcion
                }
                fontc={"var(--celeste)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={
                      proyectos.fields.breitSite.fields.programa2.fields.imagen
                        .fields.file.url
                    }
                    alt="breit5"
                  />
                </Cardcontainer>
              </Historia>

              <br></br>
              <br></br>

              <Bottomsection
                card1={
                  proyectos.fields.breitSite.fields.programa2.fields.cards[0]
                    .fields.file.url
                }
                card2={
                  proyectos.fields.breitSite.fields.programa2.fields.cards[1]
                    .fields.file.url
                }
                fb={proyectos.fields.breitSite.fields.redes.fields.facebook}
                ig={proyectos.fields.breitSite.fields.redes.fields.instagram}
                ld={proyectos.fields.breitSite.fields.redes.fields.linkedin}
              ></Bottomsection>
              {proyectos.fields.innovacionSocialPaisSite.fields
                .proyectoArcoirisSite.fields.banner.fields.bannerbottomPage ? (
                <Link
                  to={
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoArcoirisSite.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerImage
                        .fields.file.url
                    }
                    alt={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
