import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout.js";
import useContentful from "../../utils/useContentful";
import Titlerich from "../../components/Titlerich.js";
import Button from "../../components/Boton.js";
import "../../styles/global.css";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import Home from "../../images/Home/home.png";
import { Link } from "gatsby";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Ubicanoscontainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
    width: calc(100% - 60px);
  }
`;

const Leftcont = styled.div`
  width: 50%;
  justify-content: center;
  img {
    width: 90%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Rightcont = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 900px) {
    width: 100%;

    h2 {
      width: 100%;
    }
  }

  h2 {
    width: 100%;
  }

  h2 {
    font-weight: 700;
    font-size: 38px;
    color: var(--celeste);
    margin-bottom: 32px;
  }

  h2 {
    font-weight: 700;
    font-size: 38px;
    color: var(--magenta);
    margin-bottom: 32px;
    width: 100%;
  }
`;
const Grayblock = styled.div`
  width: 60%;
  height: 250px;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 24px;
  justify-content: space-evenly;
  margin-bottom: 38px;
  margin-top: 32px;

  @media (max-width: 900px) {
    width: 100%;
  }

  a {
  }
  h2 {
    color: var(--gris);
    font-weight: 700;
    font-size: 38px;
  }

  p {
    color: var(--gris);
  }
`;

const Bton = styled.div`
  width: 50%;
  display: flex;
  //justify-content:center;
`;
const Iframes = styled.iframe`
  width: 90%;
  height: 720px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const InformacionEntry = "8zG9dMMm9VFjkRLfm9y3W";
const ContactanosEntry = "4Zb7DOxbDAIDXTtKbzl8so";

const IndexPage = ({ location }) => {
  const { data: general, fetched } = useContentful({ id: InformacionEntry });
  const { data: contactanos, fetched: fetched2 } = useContentful({
    id: ContactanosEntry,
  });


  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{contactanos.fields.pageTitle}</title>
            <meta
              name={contactanos.fields.metaDescription}
              content={contactanos.fields.metaContent}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/contacto/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/contacto/"
            />
            <link rel="canonical" href="https://www.aporta.org.pe/contacto/" />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Ubícanos",
                    item: "https://www.aporta.org.pe/contacto/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/quienesomos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/contacto"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Ubícanos
                </Link>
              </Breadcrumbs>
              <Titlerich
                colortext={"var(--negro)"}
                container={contactanos.fields.titlerich}
                color={"var(--celeste"}
                colorbold={"var(--negro"}
              ></Titlerich>
              <br></br>
              <br></br>
              <br></br>

              <Ubicanoscontainer>
                <Subcontainer>
                  <Leftcont>
                    <Iframes
                      id="gmap_canvas"
                      src={`${contactanos.fields.mapsurl}`}
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></Iframes>
                  </Leftcont>
                  <Rightcont>
                    <Grayblock>
                      {/*<p>{informacion.fields.direccion}</p>*/}
                      <h2>Brindanos tu feedback</h2>
                      <a
                        href={contactanos.fields.feedbackurl}
                        style={{ textDecoration: "none" }}
                      >
                        <Bton>
                          <Button>Aquí</Button>
                        </Bton>
                      </a>
                    </Grayblock>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h2>{contactanos.fields.postulaText}</h2>
                    <a
                      target="_blank"
                      href={contactanos.fields.postulaform}
                      style={{ textDecoration: "none" }}
                    >
                      <Bton>
                        <Button>Postular</Button>
                      </Bton>
                    </a>

                    {/*<h1>{contactanos.fields.contactoText}</h1>
<InputStyled type="text" placeholder="Nombres y apellidos"></InputStyled>
<InputStyled type="text" placeholder="Correo electrónico"></InputStyled>
<InputStyled type="text" placeholder="Consulta"></InputStyled>
<Bton><Button>Enviar</Button></Bton>*/}
                  </Rightcont>
                </Subcontainer>
              </Ubicanoscontainer>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              {/*<Postulacontainer>
<Subcontainer>
          <Leftcont>
<img src={contactanos.fields.postulaImagen.fields.file.url}/>
          </Leftcont>
          <Rightcont>
          <h2>{contactanos.fields.postulaText}</h2>
{/*<InputStyled type="text" placeholder="Nombres y apellidos"></InputStyled>
<InputStyled type="text" placeholder="Correo electrónico"></InputStyled>
<InputStyled type="text" placeholder="Agregar archivo"></InputStyled>
<a target="_blank" href={contactanos.fields.postulaform} style={{textDecoration:'none'}}><Bton><Button>Postular</Button></Bton></a>
          </Rightcont>
        </Subcontainer>
         </Postulacontainer>*/}
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
