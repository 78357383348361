import * as React from "react";
import styled from "styled-components";
import Footer from "./Footer.js";
import Navbart from "./Navbart.js";

import MetaTags from 'react-meta-tags';


const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Containerbig = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  max-width: ${(p) => (p.margenes ? "1440px" : "auto")};
  left: ${(p) => (p.margenes ? "calc( 50vw - 720px);" : "auto")};
  // margin: 0px;
`;

const Layoutt = (props) => {

  const organizationStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "APORTA",
    "alternateName": "APORTA",
    "url": "https://www.aporta.org.pe/",
    "logo": "https://www.aporta.org.pe/static/Logoaportawhite-e2a207da1134ee4ba340ed26b8ac374f.svg",
    "sameAs": "https://es-la.facebook.com/aportainnovacion/"
  };

  const websiteStructuredData = {
    "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "APORTA",
  "url": "https://www.aporta.org.pe/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "{search_term_string}",
    "query-input": "required name=search_term_string"
  }

  };

  return (
    <Container>
 <MetaTags>
<meta name="google-site-verification" content="c-CkNvSypdLjk9SuiDHNbxhodMkU30DIrW5XbjJtwK0" />
<meta
              name="msvalidate.01"
              content="EEB4E3DF6E252A42431BC19B7E3A6E72"
            />
<meta property="og:type" content="business.business"/>
<meta property="og:title" content="APORTA"/>
<meta property="og:url" content="https://www.aporta.org.pe/"/>
<meta property="og:image" content="https://www.aporta.org.pe/static/Logoaportawhite-e2a207da1134ee4ba340ed26b8ac374f.svg"/>
<meta property="og:description" content="Somos la plataforma de impacto social de Breca. Trabajamos con las empresas del grupo, individualmente y a nivel transversal, diseñando proyectos de impacto social para convertir las necesidades de nuestros grupos de interés más vulnerables en oportunidades de progreso y desarrollo sostenible."/>
<meta property="business:contact_data:street_address" content="Calle Dionisio Derteano 144 Piso 19"/>
<meta property="business:contact_data:locality" content="San Isidro"/>
<meta property="business:contact_data:region" content="Lima"/>
<meta property="business:contact_data:country_name" content="Peru"/>
<meta name= "robots" content="index,follow" />
<script type="application/ld+json">
{JSON.stringify(organizationStructuredData)}
</script>

<script type="application/ld+json">
{JSON.stringify(websiteStructuredData)}
</script>

<script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-WK0XH7PKE8');
`}
</script>

</MetaTags>
      <Navbart />
      <Containerbig margenes={props.margen}>{props.children}</Containerbig>
      <Footer direccion={props.direccion} fb={props.facebook} instagram={props.instagram} linkedin ={props.linkedin} correo={props.email} telefono={props.telefono} youtube={props.youtube}/>
    </Container>
  );
};

export default Layoutt;
