import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful2 from "../../../../utils/useContentful2";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Titlerich from "../../../../components/Titlerich.js";
import Historia from "../../../../components/proyectos/pais/Historia.js";
import Sideimage from "../../../../components/proyectos/pais/Sideimage.js";
import Sliderphotos from "../../../../components/quienessomos/Historia/Sliderphotos.js";
import Bottomsection from "../../../../components/proyectos/pais/arcoiris/Bottomsection.js";
import "../../../../styles/global.css";
import "../../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../../images/Home/home.png";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 160px;
    height: 100px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  @media (max-width: 900px) {
    padding: 0px 0%;
  }
`;

const Titulo = styled.div`
  width: 90%;
  max-width: 1440px;
  font-size: 38px;
  color: var(--verde);
  font-weight: 700;
  padding: 0px 4%;
`;

const Texto = styled.div`
  width: 90%;
  max-width: 1440px;
  color: var(--gris);
  padding: 0px 4%;
`;

const Imgstyled = styled.img`
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });


  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitlePais[1]}</title>
            <meta
              name={proyectos.fields.metaDescriptionPais[1]}
              content={proyectos.fields.metaContentPais[1]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/arcoiris/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/arcoiris/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/arcoiris/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Pais`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Proyecto Arcoíris`,
                    item: `https://www.aporta.org.pe/proyectos/pais/arcoiris`,
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social País
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais/arcoiris"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Proyecto Arcoíris
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--verde"}
                colortext={"var(--negro)"}
                container={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.tituloRich
                }
                colorbold={"var(--negro"}
              ></Titlerich>
              <Pstyled>
                {
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.subtitulo
                }
              </Pstyled>
              <br></br>
              <Sideimage
                image={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.arcoirisDescripcionImagen
                    .fields.file.url
                }
                colorb={"var(--verde)"}
              >
                <Contentbar>
                  <img
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.logoArcoiris.fields.file
                        .url
                    }
                    alt="arcoiris 1"
                  />
                  {
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoArcoirisSite.fields.arcoirisDescripcion
                  }
                </Contentbar>
              </Sideimage>
              <br></br>
              <br></br>
              <Historia
                titulo={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.historiaArcoiris
                }
                texto={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.historiaArcoirisSecundario
                }
                fontc={"var(--verde)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <Imgstyled
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.historiaArcoirisImagen
                        .fields.file.url
                    }
                    alt="arcoiris 2"
                  />
                </Cardcontainer>
              </Historia>
              <br></br>
              <br></br>
              {proyectos.fields.innovacionSocialPaisSite.fields
                .proyectoArcoirisSite.fields.banner.fields.bannermiddlePage ? (
                <Link
                  to={
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoArcoirisSite.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerImage
                        .fields.file.url
                    }
                    alt={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
              <br></br>
              <Titulo>
                {
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.queHicimosTitulo
                }
              </Titulo>
              <Texto>
                {
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.queHicimosDescripcion
                }
              </Texto>

              <Sliderphotos
                galeria={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.galeria
                }
              ></Sliderphotos>
              <Historia
                titulo={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.emergenciaSanitaria
                }
                texto={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.emergenciaSanitariaSecundario
                }
                fontc={"var(--verde)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <Imgstyled
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.emergenciaSanitariaImagen
                        .fields.file.url
                    }
                    alt="arcoiris 3"
                  />
                </Cardcontainer>
              </Historia>
              <br></br>
              <br></br>
              <br></br>
              <Bottomsection
                clr={"var(--verde)"}
                titulo={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.reto
                }
                subtitulo={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.retoSecundario
                }
                fb={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.facebook
                }
                ig={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.instagram
                }
                youtube={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoArcoirisSite.fields.youtube
                }
              ></Bottomsection>
              {proyectos.fields.innovacionSocialPaisSite.fields
                .proyectoArcoirisSite.fields.banner.fields.bannerbottomPage ? (
                <Link
                  to={
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoArcoirisSite.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerImage
                        .fields.file.url
                    }
                    alt={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoArcoirisSite.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
