import * as React from "react";
import styled from "styled-components";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 80%;
  aspect-ratio: 1.4;
  object-fit: cover;
`;

const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: 400px;
  padding: 0px 0px;
  margin-bottom: 20px;
  margin-top: 20px;
  z-index: 1;
  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 900px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const Sliderphotos = ({ galeria }) => {
  return (
    <Container>
      <Subcontainer>
        <CarouselStyled
          arrows={true}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}
        >
          {galeria.map((item, index) => {
            return <Image src={item.fields.file.url} />;
          })}
        </CarouselStyled>
      </Subcontainer>
    </Container>
  );
};

export default Sliderphotos;
