import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useContentful from "../../../utils/useContentful";
import { Helmet } from "react-helmet";
import Layout from "../../../components/Layout/Layout.js";
import MetaTags from "react-meta-tags";
import PublicationTitle from "../../../components/impacto/PublicationTitle";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { CustomBreadcrumb } from "../../../components/CustomBreadcrumb";

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ImpactoEntry = "2oAZfLN7xDHsGeWDVtd6pk";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
`;

const Containerpdf = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const links = [
  { name: "Impacto", to: "/impacto" },
  { name: "Publicaciones", to: "/impacto/publicaciones" },
];

const Pubpage = (props) => {
  const [datapage, setDatapage] = useState([]);
  let id_ = props.params;
 

  const { data: impacto, fetched } = useContentful({ id: ImpactoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  async function f1() {
    if (id_ && id_.id.includes(".")) {
      const newid = id_.id.replaceAll(".", "?");

      id_.id = newid;
    }

    if (impacto != null) {
      let indpage = await impacto.fields.publicaciones.filter(
        (ind) =>
          ind.fields.slug
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll(/[¿]|[?]|[.]|[:]/g, "")
            .replace(/ *\([^)]*\) */g, "")
            .toLowerCase() == id_.id.replaceAll("--", " ")
      );

      setDatapage(indpage);

      //
    }
  }
  useEffect(() => {
    f1();
  }, [impacto]);

  const articlePage = (props) => {
   
    return (
      <Layout
        margen={false}
        direccion={general.fields.direccion}
        facebook={general.fields.facebook}
        instagram={general.fields.instagram}
        linkedin={general.fields.linkedin}
        email={general.fields.email}
        telefono={general.fields.telefono}
        youtube={general.fields.youtube}
      >
        <MetaTags>
          <title>{props[0].fields.seoTitle || props[0].fields.titulo}</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.4 shrink-to-fit=no"
          />
          <meta
            name="description"
            content={props[0].fields.seoDescription || props[0].fields.titulo}
          />
          <meta name="robots" content="index,follow" />
          <link
            rel="alternate"
            hrefLang="es-PE"
            href={`https://www.aporta.org.pe/impacto/publicacion/${id_.id}`}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`https://www.aporta.org.pe/impacto/publicacion/${id_.id}`}
          />
          <link
            rel="canonical"
            href={`https://www.aporta.org.pe/impacto/publicacion/${id_.id}`}
          />
        </MetaTags>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Aporta",
                  item: "https://www.aporta.org.pe/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Impacto",
                  item: "https://www.aporta.org.pe/impacto/",
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: "Publicaciones",
                  item: "https://www.aporta.org.pe/impacto/publicacion/",
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: ` ${id_.id}`,
                  item: `https://www.aporta.org.pe/impacto/publicacion/${id_.id}`,
                },
              ],
            })}
          </script>
        </Helmet>
        <Container>
          <br></br>
          <br></br>
          <CustomBreadcrumb
            links={links}
            currentPage={props[0].fields.titulo}
          />
          <PublicationTitle title={props[0].fields.titulo}></PublicationTitle>
          <Containerpdf>
            <br></br>
            <br></br>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <div style={{ width: "90%" }} id="pdfviewer">
                <Toolbar />
                <Viewer
                  fileUrl={props[0].fields.pdf.fields.file.url}
                  plugins={[toolbarPluginInstance]}
                />
              </div>
            </Worker>
            <br></br>
            <br></br>
          </Containerpdf>
        </Container>
      </Layout>
    );
  };
 
  return (
    <>
      {fetched && fetched2 && datapage.length ? (
        <>{articlePage(datapage)}</>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};
export default Pubpage;
