import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
width:100%;
display:flex;
height:auto;
justify-content:center;
position:relative;
margin:0px ;

@media (max-width: 900px) {
  height:auto;
  margin-top:48px ;
}

@media (max-width: 500px) {
  margin-top:0 ;
}

`

const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;
height: 100%;
display: flex;
justify-content:center;
flex-direction: row ;
position: relative;
align-items: center;
@media (max-width: 900px) {
  width: 100%;
  flex-direction: column ;
}
`

const Barcolo= styled.div`
position:absolute;
width: 100%;
height:100%;
top:0px;
background-color:${p=>p.clr};
z-index:0;


@media (max-width: 900px) {
  height:100%; 
}
`

const Image = styled.div`
display: flex;
width: 50%;

padding:5%;
z-index:1;
position: relative;
top: 0px;

img{width:100%;}

@media (max-width: 900px) {
  width: 100%;
  padding: 0px 30px;
  //aspect-ratio:1;
  height:auto;

  img{
    width:100%;
    margin-top:20px ;
    aspect-ratio:1;
  height:auto;
  }

}

@media (max-width: 600px) {
  width: 100%;
  padding: 0px 30px;
  //aspect-ratio:1;
  height:auto;

  img{
    width:100%;
    aspect-ratio:1;
  height:auto;
  object-fit: contain;
  margin-top:0 ;

  }

}

`

const Content = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
width: 50%;
height: 100%;
top:0px;
padding:2%;
z-index:2;
color: var(--blanco);
align-items: center;

p{
  margin-bottom:8px ;
}

@media (max-width: 900px) {

  width:100% ;
  height: auto;
  padding:50px 30px ;
  top:30px;
}

@media (max-width: 500px) {

width:100% ;
height: auto;
padding:0 ;
top:0;
}
`


const Sideimagelargevolar = ({image,left ,  colorb , children}) => {

    return(
     
        <Container  >
            <Subcontainer>
              <Image left={left}><img src={image} alt="volar"></img>  </Image>
                    <Content left={left}>
                 {children}
    </Content>          
        </Subcontainer>
        <Barcolo clr={colorb}>
            </Barcolo>
    </Container>
      
    )
    }
    export default Sideimagelargevolar;