import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../utils/BoldContentful.jsx";

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 650px;
  justify-content: center;
  background-color: rgba(${(p) => p.coloropaco}, 0.1);
  margin-bottom: 80px;

  @media (max-width: 900px) {
    height: auto;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 900px) {
    width: calc(100% - 60px);
    flex-direction: column-reverse;
  }
`;

const Text = styled.div`
  width: 100%;
`;

const Image = styled.div`
  width: 50%;
  padding: 7% 5% 5% 5%;
  margin: 0px;
  img {
    width: 100%;
    padding: 0%;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0%;
    margin-bottom: 24px;
    margin-top: 40px;
  }
`;

const Textcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 7%;
  color: var(--gris);
  justify-content: center;

  h2 {
    font-size: 38px;
    font-weight: 800;
    color: ${(p) => p.colorfont};
  }

  h2 {
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    color: ${(p) => p.colorfont};
  }
  p {
    margin-bottom: 8px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0px;
  }
`;

const Paragraph = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return <Text>{t.value} </Text>;
      })}
      <br></br>
    </>
  );
};

const Section = ({ text, image, titulo, subtitulo, color, colorsoft }) => {
  return (
    <Container coloropaco={colorsoft}>
      <Subcontainer>
        <Textcontainer colorfont={color}>
          <h2>{titulo}</h2>
          <h3>{subtitulo}</h3>
          {/* {text.map((field,index)=>{
                    return(
                    <Paragraph content ={field.content}></Paragraph>
                    );
                }
                )
                }*/}
          {BoldContentful(text, "var(--gris")}
        </Textcontainer>
        <Image>
          <img src={image} alt="sectionimage" />
        </Image>
      </Subcontainer>
    </Container>
  );
};

export default Section;
