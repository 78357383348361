import * as React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful";
import Hero from "../../../components/quienessomos/Hero.js";
import Titlerichmain from "../../../components/Titlerichmain.js";
import Historia from "../../../components/quienessomos/Historia/Historia.js";
import Sideimage from "../../../components/quienessomos/Historia/Sideimage.js";
import Sliderphotos from "../../../components/quienessomos/Historia/Sliderphotos.js";
import Navegation from "../../../components/quienessomos/Navegation.js";
import MetaTags from "react-meta-tags";
import "../../../styles/global.css";
import { Helmet } from "react-helmet";
import Home from "../../../images/Home/home.png";
import { Link } from "gatsby";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Text = styled.div`
  width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const Paragraph = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return <Text>{t.value} </Text>;
      })}
      <br></br>
    </>
  );
};

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const HistoriaquiensomosEntry = "7doT9vDWdYx991VvfAU4zs";

const IndexPage = ({ location }) => {
  const { data: historia, fetched } = useContentful({
    id: HistoriaquiensomosEntry,
  });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  console.log(historia)

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title> {historia.fields.pageTitle} </title>
            <meta
              name={historia.fields.metaName}
              content={historia.fields.metaContent}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/quienesomos/historia/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/quienesomos/historia/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/quienesomos/historia/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Quiénes Somos",
                    item: "https://www.aporta.org.pe/quienesomos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: "Nuestra Historia",
                    item: "https://www.aporta.org.pe/quienesomos/historia/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Hero
                imagen={historia.fields.headerImagenDeFondo.fields.file.url}
                title={"Desafiamos el STATUS QUO para"}
                hand={"Inspirar nuevas realidades"}
              ></Hero>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/quienesomos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/quienesomos"
                  className={
                    location.pathname.startsWith("/quienesomos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Quiénes Somos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/quienesomos/historia"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Historia
                </Link>
              </Breadcrumbs>
              <Navegation selection={"1"}></Navegation>
              <Titlerichmain
                color={"var(--magenta"}
                colortext={"var(--negro)"}
                container={historia.fields.tituloRich}
                colorbold={"var(--magenta"}
              ></Titlerichmain>
              <Historia
                image={historia.fields.seccion1Imagen.fields.file.url}
                text={historia.fields.seccion1.content}
              ></Historia>
              <Sideimage
                image={historia.fields.seccion2Imagen.fields.file.url}
                colorb={"var(--magenta"}
              >
                {historia.fields.seccion2.content.map((field, index) => {
                  return <Paragraph content={field.content}></Paragraph>;
                })}
              </Sideimage>
              <Sliderphotos galeria={historia.fields.galeria}></Sliderphotos>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
