import * as React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful";

import Navegation from "../../../components/quienessomos/Navegation.js";
import Hero from "../../../components/quienessomos/Hero.js";
import Team from "../../../components/quienessomos/Equipo/Team.js";
import Titlerichmain from "../../../components/Titlerichmain.js";
import "../../../styles/global.css";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import Home from "../../../images/Home/home.png";
import { Link } from "gatsby";
import { useEffect } from "react";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const EquipoEntry = "3pKSKXG6toPNXFEYGTX3L6";

const IndexPage = ({ location }) => {
  const { data: equipo, fetched } = useContentful({ id: EquipoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  useEffect(() => {
    sessionStorage.setItem('equipo_data', equipo);

  }, [equipo])
  

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title> {equipo.fields.pageTitle} </title>
            <meta
              name={equipo.fields.metaName}
              content={equipo.fields.metaContent}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/quienesomos/equipo/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/quienesomos/equipo/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/quienesomos/equipo/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Quiénes Somos",
                    item: "https://www.aporta.org.pe/quienesomos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: "Nuestros Equipo",
                    item: "https://www.aporta.org.pe/quienesomos/equipo/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Hero
                imagen={equipo.fields.headerImagenDeFondo.fields.file.url}
                title={"Desafiamos el STATUS QUO para"}
                hand={"Inspirar nuevas realidades"}
              ></Hero>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/quienesomos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/quienesomos"
                  className={
                    location.pathname.startsWith("/quienesomos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Quiénes Somos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/quienesomos/equipo"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Nuestro Equipo
                </Link>
              </Breadcrumbs>
              <Navegation selection={"3"}></Navegation>
              <Titlerichmain
                colortext={"var(--negro)"}
                container={equipo.fields.tituloRich}
                color={"var(--morado)"}
                colorbold={"var(--morado)"}
              ></Titlerichmain>
              <p style={{ color: "var(--gris)", padding: "20px 10px" }}>
                {equipo.fields.subtitulo}
              </p>
              <Team cards={equipo.fields.equipos}></Team>
              {/* <Historia image ={historia.fields.seccion1Imagen.fields.file.url}  text={historia.fields.seccion1}></Historia>
          <Sideimage image={historia.fields.seccion2Imagen.fields.file.url} text={historia.fields.seccion2} ></Sideimage>
          <Sliderphotos galeria={"hola"}></Sliderphotos>*/}
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
