import * as React from "react";
import styled from "styled-components";
import Button from "../Boton.js"



const Container = styled.div`
position: relative;
width: 100%;
height:648px;
background-color:var(--blanco);
padding:0vw 0vw;
display: flex;
flex-direction: column;
justify-items:center;
justify-content: center;
align-items: center;

@media (max-width: 900px) {
        padding:0px 30px;
        height:580px;
}

`
const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;

@media (max-width: 900px) {
  width: 100%;
}
`

const Titulo = styled.div`
display: flex;
flex-direction:row;
justify-content: center;
.normal {
color: var(--negro);
padding:0px;
        margin:0px;
}

.verde {
        color: var(--blanco); 
        background-color: var(--verde);
        font-weight: 1000;
        text-transform:uppercase;
        padding: 0px 4px;
        margin: 0px 8px;


}


`

const Hand = styled.div`
display: flex;
flex-direction: row;
justify-content:center;
.verde{
        font-size:80px;
        font-family: 'Just Another Hand';
        color: var(--verde);
        padding:0px;
        margin:0px;
        line-height: 70px;
}

.azul{
        font-size:80px;
        font-family: 'Just Another Hand';
        color: var(--celeste);
        padding:0px;
        margin:0px 16px;
        line-height: 70px;
}


@media (max-width: 900px) {
        font-size:45px;
        .verde{
        font-size:45px;
        line-height: 45px;
}
.azul{
        font-size:45px;
        line-height: 45px;
}
}
`

const Texto = styled.div`
text-align: center;
color: var(--gris);
padding:60px 24% 0px 24%;

@media (max-width: 900px) {
        padding:42px 0px 0px 0px;

}

`

const Boton = styled.div`
display: flex;
justify-content: center;
margin: 40px 0;

`

const Estrellas = styled.div`
position: absolute;
width: 223px;
height:103px;
background-image: url('/images/inicio/estrellas1.png');
background-size:cover;
left:97px;
top:0px;
@media (max-width: 900px) {
        display: none;
}

`

const Lateral = styled.div`
position: absolute;
font-size:120px;
font-weight:1000;
transform: rotate(-90deg);
-webkit-text-stroke: 2px var(--verde);
color: var(--blanco);
opacity:0.15;
bottom: 220px;
right: -140px;

@media (max-width: 900px) {
        display: none;
}
`

const Intro = ({ texto , textoverde,texto2,hand1 , hand2 ,content , boton }) => {

return(
    <Container>
            
        <Titulo>
<p className="normal s32">{texto}</p><p className="verde s32">{textoverde}</p><p className="normal s32">{texto2}</p>
        </Titulo>
        <Hand>
        <p className="verde ">{hand1}</p><p className="azul ">{hand2}</p>
        </Hand>
        <Subcontainer>
        <Texto>
{content}
        </Texto>
        <Boton>
<Button enlace="/quienesomos/historia">{boton}</Button>
        </Boton>
        <Estrellas>

        </Estrellas>
        
        </Subcontainer>
        <Lateral>
INSPIRAR
        </Lateral>
    </Container>
)

}

export default Intro;